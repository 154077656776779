import React, { useState, useEffect } from 'react';
import {
	Form,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	FormInput,
	FormTextarea
} from 'shards-react';
import Auth from '../../packs/auth';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addAnnouncement, updateAnnouncement, deleteAnnouncement } from './';
import AnnouncementList from './AnnouncementList';
import handleErrors from '../../packs/handleErrors';

const Announcements = props => {
	const [open, setOpen] = useState(false);
	const [announcement, setAnnouncement] = useState({
		title: '',
		body: ''
	});
	const [announcements, setAnnouncements] = useState([]);

	useEffect(() => {
		if (props.announcements.length > 0) {
			setAnnouncements(props.announcements);
		} else {
			setAnnouncements([]);
		}
	}, [props.announcements]);

	const toggleModal = () => {
		setOpen(!open);
		setAnnouncement({
			title: '',
			body: ''
		});
	};

	const handleChange = ({ target }) => {
		const { name, value } = target;
		const newAnnouncement = { ...announcement };
		newAnnouncement[name] = value;
		setAnnouncement(newAnnouncement);
	};

	const handleEdit = data => {
		setAnnouncement(data);
		setOpen(true);
	};

	const handleDelete = async (id) => {
		try {
			await Auth.fetch({
				url: `/announcements/${id}`,
				method: 'DELETE'
			});

			props.deleteAnnouncement({id});
			toast.success('Announcement deleted...');
		} catch(err) {
			handleErrors(err);
		}
	}

	const onSubmit = async e => {
		e.preventDefault();
		let url = `/announcements`;
		let method = 'POST';

		if (announcement.id) {
			url = `${url}/${announcement.id}`;
			method = 'PUT';
		}

		const resetState = () => {
			setOpen(false);
			setAnnouncement({
				title: '',
				body: ''
			});
		};

		try {
			const { data } = await Auth.fetch({
				url,
				method,
				data: { announcement }
			});
			toast.success('Announcement saved...');
			if (method === 'POST') {
				props.addAnnouncement(data);
			} else {
				props.updateAnnouncement(data);
			}

			resetState();
		} catch (err) {
			handleErrors(err);
			resetState();
		}
	};

	return (
		<>
			<h3>
				<i className='lni lni-bullhorn mr-2' /> Announcements
				<Button size='sm' className='ml-2' onClick={toggleModal}>
					New
					<i className='lni lni-bullhorn ml-2' />
				</Button>
			</h3>
			<br />
			{announcements.length > 0 &&
				announcements.map(item => (
					<AnnouncementList
						announcement={item}
						key={item.id}
						onEdit={handleEdit}
						onDelete={handleDelete}
					/>
				))}

			<Modal open={open} toggle={toggleModal}>
				<ModalHeader>
					Announcement
					<button
						className='btn position-absolute border-0 bg-white p-2'
						style={{ right: 0, top: 0 }}
						onClick={toggleModal}
					>
						<i className='lni lni-close' />
					</button>
				</ModalHeader>
				<ModalBody>
					<Form onSubmit={onSubmit}>
						<FormGroup>
							<label htmlFor='#title'>Title</label>
							<FormInput
								id='title'
								name='title'
								value={announcement.title}
								onChange={handleChange}
							/>
						</FormGroup>
						<FormGroup>
							<label htmlFor='#body'>Message</label>
							<FormTextarea
								id='body'
								name='body'
								value={announcement.body}
								onChange={handleChange}
							/>
						</FormGroup>
						<FormGroup>
							<Button type='submit'>Save</Button>
						</FormGroup>
					</Form>
				</ModalBody>
			</Modal>
		</>
	);
};

const mapDispatchToProps = { addAnnouncement, updateAnnouncement, deleteAnnouncement };
const mapStateToProps = state => ({
	announcements: state.announcements
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Announcements)
);
