import React from 'react';
import {
	Form,
	FormInput,
	FormGroup,
	Card,
	CardHeader,
	CardBody,
	Button,
	Container,
	CardTitle,
} from 'shards-react';
import Axios from 'axios';
import handleErrors from '../packs/handleErrors';
import {Link} from 'react-router-dom';

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: false,
			email: ''
		};
	}

	login = async e => {
		e.preventDefault();
		const { email } = this.state;
		this.setState({ processing: true });
		try {
			await Axios.post('/reset_password', { email });
			toast.success('Success! We sent you an email that contains your new password.')
		} catch (err) {
			this.setState({ processing: false });
			handleErrors(err)
		}
	};

	handleEmail = ({target}) => {
    this.setState({email: target.value});
  }

	render() {
		const { processing } = this.state;
		return (
			<Container className='d-flex justify-content-center align-items-center min-vh-100'>
				<Card style={{ maxWidth: '300px' }}>
					<CardHeader className='bg-primary text-white'>
						Boston Pediatric
						<br />
						<small className='text-white'>Dental Group</small>
					</CardHeader>
					<CardBody>
						<CardTitle>Password reset</CardTitle>
						<Form onSubmit={this.login}>
							<FormGroup>
								<label htmlFor='#email'>Email</label>
								<FormInput
									id='#email'
									placeholder='email'
									type='email'
									onChange={this.handleEmail}
									name='email'
								/>
							</FormGroup>
							<FormGroup>
								<Button onClick={this.login} type='submit'>
									{processing ? (
										<i className='lni lni-spinner lni lni-spin-effect mr-1' />
									) : (
										''
									)}
									Reset
								</Button>
							</FormGroup>
						</Form>
            <Link to="/">Sign In</Link>
					</CardBody>
				</Card>
			</Container>
		);
	}
}

export default ResetPassword;
