import React, { useState } from 'react';
import moment from 'moment';
import capitalize from 'lodash.capitalize';

const Confirm = ({ list = [], onSubmit, onAddShift, shifts, editSchedule }) => {
  const [schedules, setSchedules] = useState([]);
  const repeatables = ['weekdays', 'weekends'];

  if (list.length <= 0) return null;

  const title = () => {
    const recurringSchedules = ['weekdays', 'weekends'];

    const end_at =
      list.length === 1
        ? list[0].end_at
        : moment.max(list.map(i => moment(i.end_at)));

    const names = list.reduce((acc, i) => {
      i.names.forEach(name => acc.push(name));
      return acc;
    }, []);

    const end_date = end_at
      ? `to ${moment(end_at).format('MMM DD, YYYY')}`
      : '';

    const result = `You are adding ${names.join(
      ', '
    )} to the following shifts between
     ${moment(list[0].start_at).format('MMM DD, YYYY')}
     ${end_date}`;

    return result;
  };

  const getShift = shiftId => {
    const shift = shifts.filter(s => s.id.toString() === shiftId);
    if (shift.length >= 1) {
      return capitalize(shift[0].name);
    }
    return null;
  };

  const getShiftTime = shiftId => {
    const shift = shifts.filter(s => s.id.toString() === shiftId);
    if (shift.length >= 1) {
      const start = moment(shift[0].start_time, 'HH:mm').format('hh:mm a');
      const end = moment(shift[0].end_time, 'HH:mm').format('hh:mm a');
      return `${start} - ${end} `;
    }
    return null;
  };

  const scheduleList = () =>
    list.map(l => (
      <div className="d-flex justify-content-between shifts-list" key={l.uid}>
        <div>
          <span
            className={`cursor - pointer show - edit - icon ${l.office} `}
            onClick={() => editSchedule(l.uid)}
          >
            {capitalize(l.office)},
            {l.role
              .split('_')
              .map(str => capitalize(str))
              .join(' ')}
          </span>
          <span>
            {repeatables.includes(l.frequency) && 'Every '}
            {l.frequencies.map(str => capitalize(str)).join(', ')}
          </span>
        </div>
        <div>
          {l.shifts.map(shift => (
            <span key={shift}>{getShift(shift)}</span>
          ))}
        </div>
        <div>
          {l.shifts.map(shift => (
            <span key={`time - ${shift} `}>{getShiftTime(shift)}</span>
          ))}
        </div>
      </div>
    ));

  return (
    <>
      <h5 className="mt-4">{title()} </h5>

      {scheduleList()}
      <button
        type="button"
        className="confirm-btn"
        onClick={() => onAddShift()}
      >
        Add more shifts
      </button>
      <button type="button" className="confirm-btn" onClick={() => onSubmit()}>
        Save this selection
      </button>
    </>
  );
};

export default Confirm;
