import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import DashBoardLayout from "./DashboardLayout";
import { connect } from "react-redux";

const ProtectedRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !!user.email ? (
        <DashBoardLayout {...props}>
          <Component {...props} />
        </DashBoardLayout>
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ProtectedRoute)
);
