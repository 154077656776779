import React, { useState } from 'react';
import moment from 'moment';
import { Modal, ModalHeader } from 'shards-react';
import TimelogModalBody from './TimelogModalBody';
import Table from '../Table';
import shortHumanizer from '../../packs/shortHumanizer';

const TimelogsTable = ({ timelogs }) => {
  const [open, setOpen] = useState(false);
  const [timelog, setTimelog] = useState({});

  const convertDuration = duration => shortHumanizer(duration * 1000);

  const convertToTime = time => moment(time).format('hh:mm a');

  return (
    <React.Fragment>
      <Table headers={['Working on?', 'Duration', 'Time in', 'Time out']}>
        {timelogs.map(timelog => (
          <tr
            key={timelog.id}
            onClick={() => {
              setOpen(!open);
              setTimelog(timelog);
            }}
            style={{ cursor: 'pointer' }}
          >
            <td>{timelog.attributes.description}</td>
            <td>{convertDuration(timelog.attributes.duration)}</td>
            <td>{convertToTime(timelog.attributes.start_at)}</td>
            <td>{convertToTime(timelog.attributes.end_at)}</td>
          </tr>
        ))}
      </Table>
      <Modal
        open={open}
        size="lg"
        toggle={() => {
          setTimelog({});
          setOpen(!open);
        }}
      >
        <ModalHeader>
          Timelog #{timelog.id}
          <button
            type="button"
            className="btn position-absolute border-0 bg-white p-2"
            style={{ right: 0, top: 0 }}
            onClick={() => {
              setTimelog({});
              setOpen(false);
            }}
          >
            <i className="lni lni-close" />
          </button>
        </ModalHeader>
        <TimelogModalBody
          timelog={timelog}
          onClick={() => {
            setTimelog({});
            setOpen(false);
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

export default TimelogsTable;
