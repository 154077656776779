import axios from 'axios';
import jwt from 'jsonwebtoken';
import storageUtils from './storageUtils';

const { JWT_SECRET_KEY } = process.env;

class Auth {
  static saveToken(token) {
    storageUtils.setItem('accessToken', token);
    try {
      const data = jwt.decode(token.split(' ')[1]);
      storageUtils.setItem('avatarUrl', data.avatar);
      storageUtils.setItem('expiration', data.exp);
    } catch (err) {}
  }

  static isAdmin() {
    const data = Auth.getData();
    if (!data) {
      storageUtils.clear();
    }
    return data.role === 'admin';
  }

  static isStaff() {
    const data = Auth.getData();
    if (!data) {
      storageUtils.clear();
    }
    return data.role === 'staff';
  }

  static isPatient() {
    const data = Auth.getData();
    if (!data) {
      storageUtils.clear();
    }
    return data.role === 'patient';
  }

  static isGuest() {
    const data = Auth.getData();
    if (!data) {
      storageUtils.clear();
    }
    return data.role === 'guest';
  }

  static getToken() {
    return storageUtils.getItem('accessToken');
  }

  static getEmail() {
    return storageUtils.getItem('email');
  }

  static isAuthenticated() {
    const token = storageUtils.getItem('accessToken');
    return !!token;
  }

  static async logout() {
    try {
      await Auth.fetch({
        url: '/logout',
        method: 'DELETE',
      });
      Auth.removeAuth();
    } catch (err) {
      Auth.removeAuth();
      console.log(err.message);
    }
  }

  static removeAuth = () => {
    storageUtils.removeItem('accessToken');
    storageUtils.removeItem('email');
    storageUtils.removeItem('avatarUrl');
    storageUtils.removeItem('expiration');
  };

  static async verify() {
    try {
      const token = Auth.getToken().split(' ')[1];
      const isVerified = await jwt.verify(token, JWT_SECRET_KEY);
      return isVerified;
    } catch (err) {
      await Auth.logout();
      return false;
    }
  }

  static fetch = async ({ url, method, data, getResponseHeaders, ...rest }) => {
    const headers = {
      Authorization: Auth.getToken(),
      'Content-Type': 'application/json',
    };

    const res = await axios({
      url,
      method,
      data,
      headers,
      ...rest,
    });
    if (typeof getResponseHeaders === 'function') {
      getResponseHeaders(res.headers);
    }
    return res.data;
  };

  static getData = () => {
    try {
      const token = Auth.getToken().split(' ')[1];
      return jwt.decode(token);
    } catch (err) {
      return false;
    }
  };

  static getPatientPortalSSO = () => {
    try {
      const token = Auth.getToken().split(' ')[1];
      return `http://patient-portal.vaycay365.com/sso?token=${token}`;
    } catch (err) {
      return false;
    }
  };
}

export default Auth;
