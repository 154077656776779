import React, { useState, useEffect } from 'react';
import {
  Form,
  FormGroup,
  Col,
  FormSelect,
  FormInput,
  Alert,
} from 'shards-react';
import moment from 'moment';
import capitalize from 'lodash.capitalize';
import { toast } from 'react-toastify';
import SearchInput from '../../SearchInput';

const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
const weekends = ['saturday', 'sunday'];

const ScheduleForm = ({
  data,
  onConfirm,
  selectedDate,
  shifts,
  isEditing = false,
}) => {
  const [schedule, setSchedule] = useState({});

  useEffect(() => {
    const newSched = { ...data };
    newSched.start_at = selectedDate;
    if (newSched.shifts.length <= 0) {
      newSched.shifts = [shifts[0].id.toString()];
    }

    if (newSched.frequency === 'today') {
      newSched.end_at = moment(selectedDate)
        .endOf('day')
        .toISOString();
    }

    setSchedule(newSched);
  }, [data, selectedDate, shifts]);

  const handleChange = event => {
    const { value, name } = event.target;
    const newSchedule = { ...schedule };

    if (name === 'user_id') {
      newSchedule.user_ids = Array.from(
        new Set([...newSchedule.user_ids, value])
      );

      newSchedule.names = Array.from(
        new Set([...newSchedule.names, event.target.valueName])
      );
    } else {
      newSchedule[name] = value;
    }

    if (selectedDate) {
      newSchedule.start_at = moment(selectedDate).toISOString();
    }

    if (name === 'frequency' && value !== 'custom') {
      newSchedule.frequencies = [];
      if (value === 'today') {
        newSchedule.frequencies = [
          moment(selectedDate)
            .format('dddd')
            .toLowerCase(),
        ];
        newSchedule.end_at = moment(selectedDate)
          .endOf('day')
          .toISOString();
      } else {
        // its weekdays/weekends its repeatable forever
        newSchedule.end_at = null;
        newSchedule.frequencies = value === 'weekdays' ? weekdays : weekends;
      }
    } else {
      // its a custom frequency
      // set repeatable to false
      newSchedule.repeatable = false;
      newSchedule.end_at = moment(selectedDate)
        .endOf('isoWeek')
        .toISOString();
    }

    if (name === 'repeat_weeks_for') {
      const newVal = parseInt(value || 0);
      newSchedule[name] = newVal;
      newSchedule.end_at = moment(newSchedule.start_at)
        .add(newVal, 'isoWeek') // this will start on monday
        .endOf('day') // get the end of day
        .subtract(1, 'day') // and subtract 1 day to get the proper isoWeek end
        .toISOString();
      newSchedule.repeatable = true;
    }

    if (name === 'repeatable') {
      newSchedule[name] = value === 'true';
      if (value !== true) {
        newSchedule.repeat_weeks_for = 0;
      }
    }

    setSchedule(newSchedule);
  };

  const confirm = () => {
    if (schedule.names.length <= 0) {
      toast.error('Cant confirm, No assigned employee');
      return;
    }

    onConfirm(schedule);
  };

  const handleArrays = event => {
    const { value, name } = event.target;
    const newSchedule = { ...schedule };

    if (isEditing && name === 'shifts') {
      newSchedule.shifts = [value];
      setSchedule(newSchedule);
      return;
    }

    if (newSchedule[name].includes(value)) {
      newSchedule[name] = newSchedule[name].filter(i => i !== value);
    } else {
      newSchedule[name].push(value);
    }

    setSchedule(newSchedule);
  };

  const removeUser = index => {
    if (isEditing) return;
    const newSchedule = { ...schedule };
    newSchedule.user_ids = newSchedule.user_ids.filter(
      user => user !== newSchedule.user_ids[index]
    );
    newSchedule.names = newSchedule.names.filter(
      name => name !== newSchedule.names[index]
    );

    setSchedule(newSchedule);
  };

  if (Object.keys(schedule).length <= 0) return null;

  return (
    <>
      {moment(selectedDate).isBefore(moment(), 'day') && (
        <Alert theme="warning">
          Are you sure? You are trying to create a schedule in the past.
        </Alert>
      )}
      <h3 className="inter-font font-weight-bold font-size-18">
        Add An employee to a shift
      </h3>
      <Form>
        <label htmlFor="office" className="shifts-label">
          Who are you looking to add to the shift?
        </label>
        <FormGroup row>
          <Col>
            <FormSelect name="office" id="office" onChange={handleChange}>
              {['Arlington', 'Cambridge', 'Lynn'].map(i => (
                <option value={i.toLowerCase()} key={i}>
                  {i}
                </option>
              ))}
            </FormSelect>
          </Col>
          <Col>
            <FormSelect name="role" id="role" onChange={handleChange}>
              {['front_desk', 'hygiene', 'dentist'].map(i => (
                <option value={i} key={i}>
                  {capitalize(i.split('_').join(' '))}
                </option>
              ))}
            </FormSelect>
          </Col>
        </FormGroup>
        <FormGroup>
          <label htmlFor="employee" className="shifts-label">
            Who are you looking to add to the shift?
          </label>
          <SearchInput
            inputClassName="italic-placeholder"
            placeholder="Search for Employe Name"
            name="user_id"
            id="user_id"
            url="/admin/users"
            dataKey="id"
            clearOnComplete
            format={data => {
              const { first_name, last_name } = data.attributes.profile;
              return `${first_name} ${last_name}`;
            }}
            onChange={handleChange}
            disabled={isEditing}
          />
          <div className="d-flex flex-wrap">
            {schedule.names.map((name, index) => (
              <span className="selected-users" key={name}>
                {name}{' '}
                <i
                  className="lni lni-close cursor-pointer"
                  onClick={() => removeUser(index)}
                />
              </span>
            ))}
          </div>
        </FormGroup>
        <FormGroup>
          <h5 className="shifts-label">Which shifts are you adding them to?</h5>
          <span className="shifts-label-italic">
            You may add the employee to multiple shifts
          </span>
          <br />
          {shifts.map(s => (
            <label key={s.id} className="shifts-btn">
              <input
                type="checkbox"
                name="shifts"
                value={s.id}
                checked={schedule.shifts.includes(s.id.toString())}
                onChange={handleArrays}
              />
              <span>{s.name}</span>
            </label>
          ))}
        </FormGroup>
        <FormGroup>
          <h5 className="shifts-label">When does this apply for this week?</h5>
          <label className="shifts-btn">
            <input
              type="radio"
              name="frequency"
              value="today"
              checked={schedule.frequency === 'today'}
              onChange={handleChange}
            />
            <span>Just Today</span>
          </label>
          <label className="shifts-btn">
            <input
              type="radio"
              name="frequency"
              value="weekdays"
              checked={schedule.frequency === 'weekdays'}
              onChange={handleChange}
            />
            <span>All Weekdays</span>
          </label>
          <label className="shifts-btn">
            <input
              type="radio"
              name="frequency"
              value="weekends"
              checked={schedule.frequency === 'weekends'}
              onChange={handleChange}
            />
            <span>All Weekends</span>
          </label>
          <label className="shifts-btn">
            <input
              type="radio"
              name="frequency"
              value="custom"
              checked={schedule.frequency === 'custom'}
              onChange={handleChange}
            />
            <span>Custom</span>
          </label>
        </FormGroup>
        {schedule.frequency === 'custom' && (
          <>
            <FormGroup>
              <h5 className="shifts-label">
                Select which days?
                <br />
                <span className="shifts-label-italic">
                  You may select multiple days
                </span>
              </h5>
              <label className="shifts-btn">
                <input
                  type="checkbox"
                  name="frequencies"
                  value="monday"
                  checked={schedule.frequencies.includes('monday')}
                  onChange={handleArrays}
                />
                <span>Monday</span>
              </label>
              <label className="shifts-btn">
                <input
                  type="checkbox"
                  name="frequencies"
                  value="tuesday"
                  checked={schedule.frequencies.includes('tuesday')}
                  onChange={handleArrays}
                />
                <span>Tuesday</span>
              </label>
              <label className="shifts-btn">
                <input
                  type="checkbox"
                  name="frequencies"
                  value="wednesday"
                  checked={schedule.frequencies.includes('wednesday')}
                  onChange={handleArrays}
                />
                <span>Wednesday</span>
              </label>
              <label className="shifts-btn">
                <input
                  type="checkbox"
                  name="frequencies"
                  value="thursday"
                  checked={schedule.frequencies.includes('thursday')}
                  onChange={handleArrays}
                />
                <span>Thursday</span>
              </label>
              <label className="shifts-btn">
                <input
                  type="checkbox"
                  name="frequencies"
                  value="friday"
                  checked={schedule.frequencies.includes('friday')}
                  onChange={handleArrays}
                />
                <span>Friday</span>
              </label>
              <label className="shifts-btn">
                <input
                  type="checkbox"
                  name="frequencies"
                  value="saturday"
                  checked={schedule.frequencies.includes('saturday')}
                  onChange={handleArrays}
                />
                <span>Saturday</span>
              </label>
              <label className="shifts-btn">
                <input
                  type="checkbox"
                  name="frequencies"
                  value="sunday"
                  checked={schedule.frequencies.includes('sunday')}
                  onChange={handleArrays}
                />
                <span>Sunday</span>
              </label>
            </FormGroup>
            <h5 className="shifts-label">Repeat this selection?</h5>
            <FormGroup>
              <div className="d-flex align-items-center">
                <label className="shifts-radio mr-2">
                  <input
                    type="radio"
                    name="repeatable"
                    value="true"
                    checked={schedule.repeatable === true}
                    onChange={handleChange}
                  />
                  Yes, repeat this for the next
                  <span />
                </label>
                <FormInput
                  type="text"
                  name="repeat_weeks_for"
                  className="align-self-start"
                  value={schedule.repeat_weeks_for}
                  onChange={handleChange}
                  style={{
                    maxWidth: 53,
                    height: 31,
                    paddingLeft: 5,
                    paddingRight: 5,
                    marginRight: 10,
                  }}
                  disabled={schedule.repeatable === false}
                />
                <span
                  className="ibm-plex"
                  style={{ fontSize: 14, color: '#000' }}
                >
                  Week
                </span>
              </div>
              <label className="shifts-radio mt-3">
                <input
                  type="radio"
                  name="repeatable"
                  value="false"
                  checked={schedule.repeatable === false}
                  onChange={handleChange}
                />
                No, this is just for this week
                <span />
              </label>
            </FormGroup>
          </>
        )}
        <button type="button" className="confirm-btn mt-4" onClick={confirm}>
          Confirm this selection
        </button>
      </Form>
    </>
  );
};

export default ScheduleForm;
