import React from 'react';
import { Navbar, Nav, NavItem, NavLink } from 'shards-react';
import PropTypes from 'prop-types';
import Auth from '../packs/auth';

const SideBar = ({
  isAdmin,
  isStaff,
  isPatient,
  isGuest,
  pathname,
  notifications,
}) => {
  const displayNumberOfUpdateRequest = () => {
    if (Object.keys(notifications).length <= 0) return null;
    const { update_request } = notifications;

    if (update_request.data.length <= 0) return null;
    return (
      <span className="badge badge-pill badge-danger  text-white">
        {update_request.data.length}
      </span>
    );
  };

  return (
    <div
      className="sidebar bg-sidebar w-100 d-none d-lg-block"
      style={{ maxWidth: 200 }}
    >
      <Navbar expand="md">
        <Nav navbar className="d-flex flex-column">
          {(isStaff || isAdmin) && (
            <>
              <NavItem>
                <NavLink
                  active={pathname === '/app/announcements'}
                  href="/app/announcements"
                >
                  Announcements
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={pathname === '/app/dashboard'}
                  href="/app/dashboard"
                >
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem className="d-none">
                <NavLink href="#">Purchase Orders</NavLink>
              </NavItem>
              <NavItem className="d-none">
                <NavLink href="#">Inventories</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href={Auth.getPatientPortalSSO()}
                  className="cursor-pointer"
                >
                  Files
                </NavLink>
              </NavItem>
              <NavItem className="d-none">
                <NavLink href="#">Messages</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={pathname === '/app/timelogs'}
                  href="/app/timelogs"
                >
                  Timelog Report
                </NavLink>
              </NavItem>
              {isAdmin && (
                <>
                  <NavItem>
                    <NavLink
                      active={pathname === '/app/schedules'}
                      href="/app/schedules"
                    >
                      Schedules
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      active={pathname === '/app/users'}
                      href="/app/users"
                    >
                      Users
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={pathname === '/app/user-timelogs'}
                      href="/app/user-timelogs"
                    >
                      User Timelogs
                    </NavLink>
                  </NavItem>
                  <NavItem className="d-none">
                    <NavLink
                      active={pathname === '/app/products'}
                      href="/app/products"
                    >
                      Products
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={pathname === '/app/user-timelog-approvals'}
                      href="/app/user-timelog-approvals"
                    >
                      Update Request {displayNumberOfUpdateRequest()}
                    </NavLink>
                  </NavItem>
                  <NavItem className="d-none">
                    <NavLink
                      active={pathname === '/app/vendors'}
                      href="/app/vendors"
                    >
                      Vendors
                    </NavLink>
                  </NavItem>
                </>
              )}
              <NavItem>
                <NavLink
                  active={pathname === '/app/settings'}
                  href="/app/settings"
                >
                  Settings
                </NavLink>
              </NavItem>
            </>
          )}

          {isPatient ||
            (isGuest && (
              <>
                <NavItem>
                  <NavLink active={pathname === '/app/files'} href="/app/files">
                    Files
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={pathname === '/app/settings'}
                    href="/app/settings"
                  >
                    Settings
                  </NavLink>
                </NavItem>
              </>
            ))}
        </Nav>
      </Navbar>
    </div>
  );
};

SideBar.propTypes = {
  isAdmin: PropTypes.bool,
  isStaff: PropTypes.bool,
  isPatient: PropTypes.bool,
  isGuest: PropTypes.bool,
  pathname: PropTypes.string,
  notifications: PropTypes.object,
};

SideBar.defaultProps = {
  isAdmin: false,
  isStaff: false,
  isPatient: false,
  isGuest: false,
  pathname: '/',
  notifications: {},
};

export default SideBar;
