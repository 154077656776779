import React, { useState, useEffect } from 'react';
import {
  FormInput,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'shards-react';
import TimePicker from 'react-time-picker';
import { toast } from 'react-toastify';
import Auth from '../../../packs/auth';
import Table from '../../Table';
import TableData from '../../TableData';
import handleErrors from '../../../packs/handleErrors';

const Shift = () => {
  const [open, setOpen] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [shift, setShift] = useState({
    name: '',
    start_time: '',
    end_time: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await Auth.fetch({
        url: '/admin/shifts',
        method: 'GET',
      });

      const newShifts = data.map(s => s.attributes);
      setShifts(newShifts);
    };

    fetchData();
  }, []);

  const handleChange = ({ target }) => {
    const newShift = { ...shift };
    const { name, value } = target;
    newShift[name] = value;
    setShift(newShift);
  };

  const handleTimeChange = name => time => {
    const newShift = { ...shift };
    newShift[name] = time;
    setShift(newShift);
  };

  const saveShift = async () => {
    try {
      let url = '/admin/shifts';
      let method = 'POST';
      if (shift.id) {
        method = 'PUT';
        url = `${url}/${shift.id}`;
      }

      const { data } = await Auth.fetch({
        url,
        method,
        data: { shift },
      });

      if (method === 'POST') {
        setShifts([...shifts, data.attributes]);
      } else {
        const newShifts = [...shifts];
        const index = newShifts.findIndex(s => s.id === shift.id);
        if (index < 0) return;
        newShifts[index] = data.attributes;
        setShifts(newShifts);
      }
      toast.success(`Shift ${method === 'POST' ? 'created' : 'updated'}`);
      setOpen(false);
    } catch (e) {
      /* handle error */
      handleErrors(e);
    }
  };

  const toggle = () => {
    setOpen(!open);
    setShift({
      name: '',
      start_time: '',
      end_time: '',
    });
  };

  const handleEdit = id => {
    const filtered = shifts.filter(s => s.id === id);
    if (filtered.length <= 0) return;
    setShift(filtered[0]);
    setOpen(true);
  };

  const handleDelete = async id => {
    const result = confirm(
      `Are you sure? Deleting a shift will also delete schedules with this shift.`
    );

    try {
      if (result) {
        await Auth.fetch({
          url: `/admin/shifts/${id}`,
          method: 'DELETE',
        });
        toast.success('Shift successfuly deleted.');
      }
    } catch (e) {
      /* handle error */
      handleErrors(e);
    }
  };

  return (
    <Row>
      <Col xs="12" md="12" lg="10" xl="7">
        <Card>
          <CardHeader>
            Shifts
            <span className="mx-2">|</span>
            <Button size="sm" onClick={toggle}>
              <i className="lni lni-plus mr-1"></i>New
            </Button>
          </CardHeader>
          <CardBody>
            <Table headers={['Description', 'Start Time', 'End Time', '']}>
              <TableData
                data={shifts}
                editable
                deletable
                onEditClick={handleEdit}
                onDeleteClick={handleDelete}
                attributes={[
                  { key: 'name' },
                  { key: 'start_time' },
                  { key: 'end_time' },
                ]}
              />
            </Table>
          </CardBody>
        </Card>
        <Modal open={open} toggle={toggle}>
          <ModalHeader>
            New Shift
            <button
              className="btn position-absolute border-0 bg-white p-2"
              style={{ right: 0, top: 0 }}
              onClick={toggle}
            >
              <i className="lni lni-close" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="d-flex flex-column mb-4 px-0">
              <label htmlFor="shift-name">Name</label>
              <FormInput
                value={shift.name}
                name="name"
                className="mb-1"
                id="shift-name"
                onChange={handleChange}
              />
              <label htmlFor="shift-start-time">Start Time</label>
              <TimePicker
                value={shift.start_time}
                name="start_time"
                className="mb-1"
                id="shift-start-time"
                onChange={handleTimeChange('start_time')}
              />
              <label htmlFor="shift-end-time">End Time</label>
              <TimePicker
                value={shift.end_time}
                name="end_time"
                className="mb-1"
                id="shift-end-time"
                onChange={handleTimeChange('end_time')}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={saveShift}>
              <i className="lni lni-save mr-1" />
              {shift.id ? 'Update' : 'Save'}
            </Button>
          </ModalFooter>
        </Modal>
      </Col>
    </Row>
  );
};

export default Shift;
