import React, { useState, useEffect } from 'react';
import Auth from '../packs/auth';
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Button,
	Badge
} from 'shards-react';
import { toast } from 'react-toastify';
import useInterval from './hooks/useInterval';
import useCable from './hooks/useCable';

const Notifications = () => {
	const [notifications, setNotifications] = useState([]);
	const [open, setOpen] = useState(false);

	const fetchNotifications = async () => {
		const { data } = await Auth.fetch({
			url: '/notifications',
			method: 'GET'
		});

		if (data) {
			const newData = data.reduce((obj, item) => {
				obj.push({
					title: item.attributes.title,
					body: item.attributes.body,
					id: item.id
				});
				return obj;
			}, []);
			setNotifications(newData);
		}
	};

	useInterval(fetchNotifications, 1000 * 60);

	const onReceived = data => {
		toast.info(
			<div>
				<strong>{data.title}</strong>
				<span>{data.body}</span>
			</div>
		);
		fetchNotifications();
	};

	useCable(onReceived, 'NotificationChannel');

	useEffect(() => {
		fetchNotifications();
	}, []);

	const deleteItem = id => {
		return async () => {
			await Auth.fetch({
				url: `/notifications/${id}`,
				method: 'DELETE'
			});
			const newNotices = notifications.filter(item => item.id !== id);
			setNotifications(newNotices);
		};
	};

	const clearNotifications = async () => {
		const promises = notifications.reduce((acc, item) => {
			acc.push(
				Auth.fetch({
					url: `/notifications/${item.id}`,
					method: 'DELETE'
				})
			);
			return acc;
		}, []);

		await Promise.all(promises);
		setNotifications([]);
	};

	const renderNotifications = () => {
		return notifications.map(item => (
			<DropdownItem key={item.id} onClick={deleteItem(item.id)}>
				<span>{item.title}</span>
				<br />
				<p>{item.body}</p>
			</DropdownItem>
		));
	};

	return (
		<Dropdown open={open} toggle={() => setOpen(!open)}>
			<DropdownToggle nav className='text-dark'>
				<Badge theme='light'>
					<i className='lni lni-alarm' /> {notifications.length}
				</Badge>
			</DropdownToggle>
			<DropdownMenu right>
				<div style={{ maxHeight: 350, overflowY: 'auto', minWidth: 300 }}>
					<h6 className='d-flex justify-content-between align-items-center p-2 px-3'>
						Notifications
						<Button
							onClick={clearNotifications}
							size='sm'
							theme='light'
							title='Clear Notifications'
						>
							<i className='lni lni-trash' />
						</Button>
					</h6>

					{notifications.length > 0 ? (
						renderNotifications()
					) : (
						<DropdownItem className='text-muted'>Empty</DropdownItem>
					)}
				</div>
			</DropdownMenu>
		</Dropdown>
	);
};

export default Notifications;
