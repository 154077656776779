import React from 'react';
import { Card, CardHeader, CardBody, Badge, Collapse } from 'shards-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import TimelogsTable from './TimelogsTable';
import shortHumanizer from '../../packs/shortHumanizer';

const mapStateToProps = state => ({
	timelogs: state.timelogs
});

const TODAY = moment();

class TimelogList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			groups: {}
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.timelogs.length > 0) {
			const groups = this.groupTimelogs(nextProps.timelogs);
			this.setState({ groups });
		}
	}

	groupTimelogs = timelogs => {
		if (!timelogs) return {};

		return timelogs.reduce((groups, item) => {
			const date = moment(item.attributes.start_at).format('YYYY-MM-DD');
			if (!groups[date]) {
				groups[date] = {
					timelogs: [],
					totalDuration: 0,
					isToday: TODAY.isSame(date, 'day')
				};
			}
			groups[date].timelogs.push(item);
			groups[date].totalDuration += parseInt(item.attributes.duration);
			groups[date].open = false;
			return groups;
		}, {});
	};

	convertToTime = duration => {
		const seconds = Math.ceil(duration) * 1000;
		return shortHumanizer(seconds);
	};

	toggle = key => {
		return () => {
			const newState = { ...this.state };
			newState.groups[key].open = !newState.groups[key].open;
			this.setState(newState);
		};
	};

	renderCards = () => {
		const { groups } = this.state;
		return Object.keys(groups).map(key => (
			<Card className='shadow-none rounded-0' key={key}>
				<CardBody className='py-2 px-0'>
					<div
						className='px-4 py-2 d-flex align-items-center border-bottom'
						onClick={this.toggle(key)}
						style={{ cursor: 'pointer' }}
					>
						<span>
							{groups[key].isToday ? 'Today' : moment(key).format('dddd')}
							<br />
							<small>{moment(key).format('DD MMM YYYY')}</small>
						</span>
						<Badge outline className='ml-auto'>
							{this.convertToTime(groups[key].totalDuration)}
						</Badge>
					</div>
					<Collapse open={groups[key].open}>
						<TimelogsTable timelogs={groups[key].timelogs} />
					</Collapse>
				</CardBody>
			</Card>
		));
	};

	render() {
		return (
			<Card className="mt-4 mt-md-0">
				<CardHeader className='border-bottom'>
					Recent Timelogs
				</CardHeader>
				<CardBody className='p-0'>
					{this.renderCards()}
					{Object.keys(this.state.groups).length <= 0 && (
						<Card className='shadow-none rounded-0'>
							<CardBody className='py-2 px-0'>
								<div className="px-4">
									<p className="text-muted">No Record.</p>
								</div>
							</CardBody>
						</Card>
					)}
				</CardBody>
			</Card>
		);
	}
}

export default withRouter(connect(mapStateToProps, null)(TimelogList));
