export default () => {
	let delay = 1000;
	const startTime = timer => {
		const timeStart = new Date().getTime();
		setTimeout(() => {
      const fix = (new Date().getTime() - timeStart) - delay
      startTime(timer - fix)
      postMessage(timer - fix)
    }, timer)
	};

	self.onmessage = event => {
    delay = parseInt(event.data);
    startTime(delay);
	};
};
