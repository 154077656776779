import React, { useState, useEffect } from 'react';
import capitalize from 'lodash.capitalize';
import moment from 'moment';
import Auth from '../../packs/auth';

const Schedule = () => {
  const [schedules, setSchedules] = useState([]);

  const fetchSchedules = async () => {
    const { data } = await Auth.fetch({
      url: `/schedules`,
      method: 'GET',
    });

    setSchedules(data);
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  const getShiftTime = sched => {
    // eslint-disable-next-line camelcase
    const { start_time, end_time } = sched.attributes.shift;
    const start = moment(start_time, 'HH:mm').format('hh:mm a');
    const end = moment(end_time, 'HH:mm').format('hh:mm a');

    return `${start} - ${end}`;
  };

  return (
    <>
      <h4
        className="inter-font font-weight-bold"
        style={{ fontSize: 18, marginTop: 35 }}
      >
        My Schedule This Week
      </h4>

      <div
        className="card-border position-relative"
        style={{ padding: '33px 21px', overflow: 'hidden', maxWidth: 664 }}
      >
        <button className="schedule-btn" type="button">
          Request to modify
        </button>
        <table className="table schedule-table">
          <thead>
            <tr>
              <th>Time</th>
              <th>Days</th>
            </tr>
          </thead>
          <tbody>
            {schedules.length > 0 &&
              schedules.map(sched => (
                <tr key={sched.id}>
                  <td>{getShiftTime(sched)}</td>
                  <td>
                    {sched.attributes.frequencies
                      .map(f => capitalize(f))
                      .join(', ')}
                  </td>
                </tr>
              ))}
            {schedules.length <= 0 && (
              <tr>
                <td colSpan={2} className="text-muted">
                  No schedule for today.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Schedule;
