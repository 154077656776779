import React from 'react';
import {
	Form,
	FormInput,
	FormGroup,
	Card,
	CardHeader,
	CardBody,
	Button,
	Container,
	CardTitle,
	Alert
} from 'shards-react';
import Axios from 'axios';
import Auth from '../packs/auth';
import { Redirect, Link, withRouter } from 'react-router-dom';
import storageUtils from '../packs/storageUtils';
import handleErrors from '../packs/handleErrors';
import {addUser, removeUser} from './auth'
import { connect } from 'react-redux';
class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: false,
			errors: [],
			user: {
				email: '',
				password: ''
			}
		};
	}

	async componentDidMount() {
		const result = await Auth.verify();
		if (result) {
			this.props.addUser(Auth.getData());
		}
	}

	login = async e => {
		e.preventDefault();
		const { user } = this.state;
		this.setState({ processing: true });
		try {
			const res = await Axios.post('/login', { user });
			// save the token from header
			this.setState({ errors: [] });
			Auth.saveToken(res.headers.authorization);
			storageUtils.setItem('email', res.data.email);

			this.props.addUser(Auth.getData());

			if (Auth.isGuest() || Auth.isPatient()) {
				this.props.history.push('/app/files');
				return;
			}
			this.props.history.push('/app/dashboard');
		} catch (err) {
			this.setState({ processing: false });
			this.props.removeUser();
			handleErrors(err);
		}
	};

	handleChange = event => {
		const { name, value } = event.target;
		const newState = { ...this.state };
		switch (name) {
			case 'email':
				newState.user.email = value;
				this.setState(newState);
			case 'password':
				newState.user.password = value;
				this.setState(newState);
			default:
				return;
		}
	};

	render() {
		const { processing } = this.state;

		if (this.props.user.email) {
			return <Redirect to='/app/dashboard' />;
		}

		return (
			<Container className='d-flex justify-content-center align-items-center min-vh-100'>
				<Card style={{ maxWidth: '300px' }}>
					<CardHeader className='bg-primary text-white'>
						Boston Pediatric
						<br />
						<small className='text-white'>Dental Group</small>
					</CardHeader>
					<CardBody>
						<CardTitle>Login</CardTitle>
						<Form onSubmit={this.login}>
							<FormGroup>
								<label htmlFor='#email'>Email</label>
								<FormInput
									id='#email'
									placeholder='email'
									type='email'
									onChange={this.handleChange}
									name='email'
								/>
							</FormGroup>
							<FormGroup>
								<label htmlFor='#password'>Password</label>
								<FormInput
									type='password'
									id='#password'
									placeholder='Password'
									onChange={this.handleChange}
									name='password'
								/>
							</FormGroup>
							<FormGroup>
								<Button onClick={this.login} type='submit'>
									{processing ? (
										<i className='lni lni-spinner lni lni-spin-effect mr-1' />
									) : (
										''
									)}
									Login
								</Button>
							</FormGroup>
						</Form>
						<Link to='/reset-password'>Reset password</Link>
					</CardBody>
				</Card>
			</Container>
		);
	}
}

const mapDispatchToProps = {addUser, removeUser};
const mapStateToProps = state => ({
	user: state.user
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
