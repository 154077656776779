import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Auth from '../../../packs/auth';

const Card = ({
  avatar_url: avatar,
  name,
  schedules,
  total_daily_time: dailyTime,
  shift_time: shiftTime,
  leaves,
  date,
  ...rest
}) => {
  const getProgress = () => {
    const d = moment(date).format('YYYY-MM-DD');
    if (leaves.length >= 1) {
      const activeLeaves = leaves.filter(l => {
        const start = moment(l.leave_start).format('YYYY-MM-DD');
        const end = moment(l.leave_end).format('YYYY-MM-DD');
        if (d >= start && d <= end) {
          return l;
        }
        false;
      });

      if (activeLeaves.length > 0) return 'paid-time-off';
    }

    if (dailyTime === 0) {
      return 'not-clocked-in';
    }

    if (dailyTime >= shiftTime) {
      return 'in-shift';
    }

    return 'clocked-out';
  };

  return (
    <div className="card-schedule" {...rest}>
      <div className="card-schedule-employee-info">
        <img
          alt="employee avatar"
          src={avatar}
          className="card-schedule-employee-info_avatar"
        />
        <div className="card-schedule-employee-info_name">
          {name}
          <a href="#">View Profile</a>
        </div>
      </div>
      <div className="card-schedule-time">
        {schedules.length > 0 &&
          schedules.map((s, index) => (
            <div
              className="card-schedule-time-items"
              key={`${s.shift}-${name.split(' ').join('_')}-${index}`}
            >
              <div>{s.shift}</div>
              <div>{s.time}</div>
            </div>
          ))}
      </div>
      <div className={`card-schedule-progress ${getProgress()}`} />
    </div>
  );
};

Card.propTypes = {
  avatar_url: PropTypes.string,
  shift_time: PropTypes.number,
  schedules: PropTypes.array,
  name: PropTypes.string,
  total_daily_time: PropTypes.number,
  leaves: PropTypes.array,
  date: PropTypes.string,
};

const DailyTimeSheets = ({ date, search, office, role }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const searchQuery = !search ? '' : `&search=${search}`;
      const roleQuery = role === 'all' ? '' : `&role=${role}`;
      const officeQuery = office === 'all' ? '' : `&office=${office}`;
      const result = await Auth.fetch({
        url: `/admin/time_sheets?daily=true&date=${date}${officeQuery}${searchQuery}${roleQuery}`,
      });
      setData(result);
    };

    if (!date) return;
    fetchData();
  }, [date, office, role, search]);

  return (
    <div className="mt-4">
      <div className="row d-flex justify-content-center my-4">
        <div className="col-6 d-flex justify-content-between">
          <span className="weekly-legend in-shift">In shift</span>
          <span className="weekly-legend shift">Clocked Out</span>
          <span className="weekly-legend not-clocked-in">Not Clocked-in</span>
          <span className="weekly-legend paid-time-off">Paid time off</span>
        </div>
      </div>
      <div className="row justify-content-center">
        {data.length > 0 &&
          data.map(d => (
            <Card
              {...d}
              date={date}
              key={d.name
                .split(' ')
                .join('_')
                .toLowerCase()}
            />
          ))}
      </div>
    </div>
  );
};

DailyTimeSheets.propTypes = {
  date: PropTypes.string,
  office: PropTypes.string,
  role: PropTypes.string,
  search: PropTypes.string,
};

export default DailyTimeSheets;
