import React, { useState, useEffect } from 'react';
import {
	Card,
	CardHeader,
	CardBody,
	ButtonGroup,
	Button,
	FormGroup,
	FormInput,
	FormTextarea,
	Alert,
	FormSelect
} from 'shards-react';
import Auth from '../../packs/auth';
import Folders from './Folders';
import Documents from './Documents';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import SharingInfo from './SharingInfo';

const MIME_TYPES = [
	'application/pdf',
	'application/vnd.ms-powerpoint',
	'text/plain',
	'application/zip',
	'application/x-compressed-zip',
	'text/csv',
	'application/epub+zip',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'application/x-rar-compressed',
	'application/rtf',
	'text/plain',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/zip',
	'application/x-7z-compressed'
];

const Files = () => {
	const [show, setShow] = useState(false);
	const [files, setFiles] = useState([]);
	const [folders, setFolder] = useState([]);
	const [sharedFolders, setSharedFolders] = useState([]);
	const [sharedFiles, setSharedFiles] = useState([]);
	const [currentDir, setCurrentDir] = useState(null);
	const [prevDir, setPrevDir] = useState(null);
	const [fileDeletionId, setFileDeletionId] = useState(null);
	const [folderDeletionId, setFolderDeletionId] = useState(null);
	const [showShareDialog, setShowShareDialog] = useState(false);
	const [rootFolderIsShared, setRootFolderIsShared] = useState(false);
	const [fileIdForSharing, setFileIdForSharing] = useState(null);
	const [folderIdForSharing, setFolderIdForSharing] = useState(null);
	const [emails, setEmails] = useState('');
	const [message, setMessage] = useState('');
	const [office, setOffice] = useState('Arlington');
	const [sharingError, setSharingError] = useState(null);
	const [sharedId, setSharedId] = useState(null);
	const [sharedType, setSharedType] = useState(null);

	const [folderIdsForSharing, setFolderIdsForSharing] = useState([]);
	const [fileIdsForSharing, setFileIdsForSharing] = useState([]);
	const [showBulkShareDialog, setShowBulkShareDialog] = useState(false);

	const offices = [
		'Arlington',
		'Cambridge',
		'Lynn'
	]

	const fetchData = async () => {
		const result = await Auth.fetch({
			url: '/documents',
			method: 'GET'
		});
		const { folders, files, shared_folders, shared_files } = result;
		setSharedFolders(shared_folders.data);
		setSharedFiles(shared_files.data);
		setFiles(files.data);
		setFolder(folders.data);
		setRootFolderIsShared(false);
	};

	const browseData = async id => {
		if (!id) return;
		const result = await Auth.fetch({
			url: `/browse/${id}`,
			method: 'GET'
		});
		const { folders, files } = result;
		setFiles(files.data);
		setFolder(folders.data);
		setSharedFolders([]);
		setSharedFiles([]);
	};

	useEffect(() => {
		if (currentDir) {
			browseData(currentDir);
			return;
		}
		fetchData();
	}, [currentDir]);

	const onUpload = async e => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('user_file[document]', file);

		if (
			!MIME_TYPES.includes(file.type) &&
			!file.type.match('image.*|audio.*|video.*')
		) {
			toast.error('Invalid file type.');
			return;
		}

		if (currentDir) {
			// set the current folder
			formData.append('user_file[folder_id]', currentDir);
		}

		const result = await Auth.fetch({
			url: '/files',
			method: 'POST',
			data: formData
		});

		if (result.data) {
			const newFiles = [...files];
			newFiles.push(result.data);
			setFiles(newFiles);
		}
	};

	const newFolder = () => setShow(true);
	const onCancel = () => setShow(false);

	const handleResponse = async response => {
		const result = await Auth.fetch({
			url: '/folders',
			method: 'POST',
			data: {
				folder: {
					name: response,
					parent_id: currentDir
				}
			}
		});

		const newFolders = [...folders];
		newFolders.push(result.data);
		setFolder(newFolders);
		setShow(false);
	};

	const onFileDelete = async () => {
		const result = await Auth.fetch({
			url: `/files/${fileDeletionId}`,
			method: 'DELETE'
		});

		if (result.status === 'Success') {
			const newFiles = files.filter(file => file.id !== fileDeletionId);
			setFiles(newFiles);
			setFileDeletionId(null);
		}
	};

	const onFolderDelete = async () => {
		const result = await Auth.fetch({
			url: `/folders/${folderDeletionId}`,
			method: 'DELETE'
		});

		if (result.status === 'Success') {
			const newFolders = folders.filter(
				folder => folder.id !== folderDeletionId
			);
			setFolder(newFolders);
			setFolderDeletionId(null);
		}
	};

	const fileDownload = async id => {
		const data = await Auth.fetch({
			url: `/download/${id}`,
			method: 'GET',
			responseType: 'blob'
		});

		if (data) {
			let result = files.filter(file => file.id === id);
			if (!result.length) {
				result = sharedFiles.filter(file => file.id === id);
			}

			const file = result[0];
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', file.attributes.filename);
			link.setAttribute('target', '_blank');
			link.click();
		}
	};

	const hasFilesAndFolders = () => {
		return (
			folders.length === 0 && files.length === 0 && sharedFolders.length === 0
		);
	};

	const handleSharing = name => {
		return id => {
			switch (name) {
				case 'file':
					setShowShareDialog(true);
					setFileIdForSharing(id);
					return;
				case 'folder':
					setShowShareDialog(true);
					setFolderIdForSharing(id);
					return;
				default:
					return;
			}
		};
	};

	const handleShare = async () => {
		let url;
		const data = {
			emails,
			message,
			office
		};

		if (fileIdForSharing) {
			url = `/share_file/${fileIdForSharing}`;
		}

		if (folderIdForSharing) {
			url = `/share_folder/${folderIdForSharing}`;
		}

		try {
			await Auth.fetch({
				url,
				method: 'POST',
				data
			});

			setShowShareDialog(false);
			setFileIdForSharing(null);
			setFolderIdForSharing(null);
			setEmails('');
			setMessage('');
		} catch (err) {
			if (err.response) {
				setSharingError(err.response.data.error);
				return;
			}

			setSharingError(err.message);
			setEmails('');
			setMessage('');
		}
	};

	const handleBulkShare = async () => {
		const data = {
			emails,
			message,
			office
		};

		try {
			const promises = [];
			if (fileIdsForSharing.length > 0) {
				promises.push(
					Auth.fetch({
						url: `/bulk_share_files`,
						method: 'POST',
						data: {
							ids: fileIdsForSharing,
							...data
						}
					})
				);
			}

			if (folderIdsForSharing.length > 0) {
				promises.push(
					Auth.fetch({
						url: `/bulk_share_folders`,
						method: 'POST',
						data: {
							ids: folderIdsForSharing,
							...data
						}
					})
				);
			}

			await Promise.all(promises);

			setShowShareDialog(false);
			setFileIdsForSharing([]);
			setFolderIdsForSharing([]);
			setEmails('');
			setMessage('');
			setOffice('Arlington');
		} catch (err) {
			if (err.response) {
				setSharingError(err.response.data.error);
				return;
			}

			setSharingError(err.message);
			setEmails('');
			setMessage('');
		}
	};

	const getShareTitle = () => {
		if (fileIdForSharing) {
			const current = files.filter(file => file.id === fileIdForSharing);
			return `Share this '${current[0].attributes.filename}' file`;
		}

		if (folderIdForSharing) {
			const current = folders.filter(
				folder => folder.id === folderIdForSharing
			);
			return `Share this '${current[0].attributes.name}' folder`;
		}
	};

	const handleProperties = type => {
		return id => {
			setSharedType(type);
			setSharedId(id);
		};
	};

	return (
		<>
			<Card>
				<CardHeader>
					<div className='d-flex justify-content-between align-items-center'>
						<div>
							<i className='lni lni-files mr-2'></i>
							Files
						</div>
						{Auth.isPatient() ? null : (
							<div>
								<ButtonGroup>
									<label
										className={`btn btn-primary btn-sm mb-0 ${rootFolderIsShared &&
											'disabled'}`}
									>
										{!rootFolderIsShared && (
											<input
												type='file'
												className='d-none'
												onChange={onUpload}
											/>
										)}
										Upload File
									</label>
									<Button
										size='sm'
										disabled={rootFolderIsShared}
										onClick={newFolder}
									>
										New Folder
									</Button>
									{(fileIdsForSharing.length > 0 ||
										folderIdsForSharing.length > 0) && (
										<Button
											size='sm'
											onClick={() => setShowBulkShareDialog(true)}
										>
											Share
										</Button>
									)}
								</ButtonGroup>
							</div>
						)}
					</div>
				</CardHeader>
				<CardBody className='px-0 overflow-x-auto'>
					<div>
						<div className='d-flex justify-content-between border-bottom mb-2'>
							<span className='mx-2 lni lni-check-mark-circle invisible' />
							<span className='col-4 col-sm-6 col-lg-6'>File Name</span>
							<span className='d-none d-md-block col-2 col-lg-1'>Size</span>
							<span className='d-none d-md-block col-2'>Modified</span>
							<span className='d-none d-sm-block col-4 col-sm-6 col-lg-3'>
								Action
							</span>
						</div>
						{prevDir >= 0 && currentDir > 0 && (
							<div
								className='col text-muted mb-2  cursor-pointer'
								onClick={() => {
									setCurrentDir(prevDir);
									setPrevDir(null);
								}}
							>
								<i className='lni lni-line-dotted' /> Back
							</div>
						)}
						<Folders
							data={sharedFolders}
							isShared
							onClick={id => {
								setPrevDir(currentDir ? currentDir : 0);
								setCurrentDir(id);
								setRootFolderIsShared(true);
							}}
						/>
						<Documents
							data={sharedFiles}
							onDelete={id => setFileDeletionId(id)}
							onDownload={fileDownload}
							isShared
						/>
						<Folders
							data={folders}
							onClick={id => {
								setPrevDir(currentDir ? currentDir : 0);
								setCurrentDir(id);
							}}
							onDelete={id => setFolderDeletionId(id)}
							onShare={handleSharing('folder')}
							onSelect={ids => setFolderIdsForSharing(ids)}
							selected={folderIdsForSharing}
							isShared={rootFolderIsShared}
							onPropertiesClick={handleProperties('folder')}
						/>
						<Documents
							data={files}
							onDelete={id => setFileDeletionId(id)}
							onDownload={fileDownload}
							onShare={handleSharing('file')}
							isShared={rootFolderIsShared}
							onSelect={ids => setFileIdsForSharing(ids)}
							selected={fileIdsForSharing}
							onPropertiesClick={handleProperties('file')}
						/>
						{hasFilesAndFolders() && (
							<div className='col text-muted ml-4'>Empty Director.</div>
						)}
					</div>
				</CardBody>
			</Card>
			<SweetAlert
				show={show}
				input
				showCancel
				cancelBtnBsStyle='default'
				title='New Folder'
				placeHolder='Enter folder name'
				onConfirm={handleResponse}
				onCancel={onCancel}
			/>
			<SweetAlert
				show={!!fileDeletionId || !!folderDeletionId}
				warning
				showCancel
				confirmBtnText='Yes, delete it!'
				confirmBtnBsStyle='danger'
				cancelBtnBsStyle='default'
				title='Are you sure?'
				onConfirm={() => {
					if (!!fileDeletionId) return onFileDelete();
					if (!!folderDeletionId) return onFolderDelete();
				}}
				onCancel={() => {
					setFileDeletionId(null);
					setFolderDeletionId(null);
				}}
				focusCancelBtn
			>
				{!!fileDeletionId && 'You will not be able to recover this file!'}

				{!!folderDeletionId &&
					'	You will not be able to recover this folder and its contents!'}
			</SweetAlert>
			<SweetAlert
				show={showBulkShareDialog}
				showCancel
				cancelBtnBsStyle='default'
				title={`Share ${fileIdsForSharing.length +
					folderIdsForSharing.length} item(s).`}
				onConfirm={handleBulkShare}
				onCancel={() => {
					setShowBulkShareDialog(false);
					setFileIdsForSharing([]);
					setFolderIdsForSharing([]);
					setSharingError(null);
				}}
			>
				<div className='text-left'>
					{sharingError && <Alert theme='danger'>{sharingError}</Alert>}
					<FormGroup>
						<label htmlFor='#emails'>Email(s)</label>
						<FormInput
							name='emails'
							id='emails'
							onChange={e => {
								setEmails(e.target.value);
							}}
							value={emails}
						/>
					</FormGroup>
					<FormGroup>
						<label htmlFor='#emails'>Office</label>
						<FormSelect value={office} onChange={e => {
							setOffice(e.target.value);
						}}>
							{offices.map(item => (
								<option key={item} value={item}>{item}</option>
							))}
						</FormSelect>
					</FormGroup>
					<FormGroup>
						<label htmlFor='#message'>Message</label>
						<FormTextarea
							id='message'
							name='message'
							onChange={e => {
								setMessage(e.target.value);
							}}
							value={message}
						/>
					</FormGroup>
					<small className='text-muted'>
						<b>Note: </b>
						Multiple emails must be separated with (,) comma
					</small>
				</div>
			</SweetAlert>
			<SweetAlert
				show={showShareDialog}
				showCancel
				cancelBtnBsStyle='default'
				title={getShareTitle() || ''}
				onConfirm={handleShare}
				onCancel={() => {
					setShowShareDialog(false);
					setFileIdForSharing(null);
					setFolderIdForSharing(null);
					setSharingError(null);
				}}
			>
				<div className='text-left'>
					{sharingError && <Alert theme='danger'>{sharingError}</Alert>}
					<FormGroup>
						<label htmlFor='#emails'>Email(s)</label>
						<FormInput
							name='emails'
							id='emails'
							onChange={e => {
								setEmails(e.target.value);
							}}
							value={emails}
						/>
					</FormGroup>
					<FormGroup>
						<label htmlFor='#emails'>Office</label>
						<FormSelect value={office} onChange={e => {
							setOffice(e.target.value);
						}}>
							{offices.map(item => (
								<option key={item} value={item}>{item}</option>
							))}
						</FormSelect>
					</FormGroup>
					<FormGroup>
						<label htmlFor='#message'>Message</label>
						<FormTextarea
							id='message'
							name='message'
							onChange={e => {
								setMessage(e.target.value);
							}}
							value={message}
						/>
					</FormGroup>
					<small className='text-muted'>
						<b>Note: </b>
						Multiple emails must be separated with (,) comma
					</small>
				</div>
			</SweetAlert>
			<SharingInfo
				type={sharedType}
				id={sharedId}
				show={sharedId !== null}
				onClose={() => {
					setSharedType(null);
					setSharedId(null);
				}}
			/>
		</>
	);
};

export default Files;
