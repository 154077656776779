import React, { useState, useEffect } from 'react';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ReferenceLine,
  Tooltip,
} from 'recharts';
import Auth from '../../../packs/auth';

const RoundedRectangle = props => {
  let defaultFill = props.fill;
  if (props.shift_time > props.total_week_time) {
    defaultFill = '#F3B7B7';
  }

  if (props.shift_time < props.total_week_time) {
    defaultFill = '#A9E1B9';
  }

  if (props.shift_time === props.total_week_time) {
    defaultFill = '#93D5EB';
  }
  return <rect {...props} rx="7" ry="7" fill={defaultFill} />;
};

const YAxisText = props => {
  const { payload, x, y } = props;
  const { value } = payload;
  if (value === 0 || value === 40) {
    return (
      <text
        x={x}
        y={y}
        fontFamily="IBM Plex Sans"
        fontSize={12}
        fontWeight="bold"
        fill="#c5cbd5"
        dy={value === 0 ? '-5' : 16}
        dx="6"
      >
        {payload.value}HR
      </text>
    );
  }
  return null;
};

const Avatar = props => {
  const { payload, x, y } = props;
  const { value } = payload;
  const url = new URL(value).searchParams;
  const name = url
    .get('name')
    .split(' ')
    .join('_');
  return (
    <>
      <defs>
        <pattern
          id={`avatar-${name}`}
          height="100%"
          width="100%"
          patternContentUnits="objectBoundingBox"
        >
          <image
            xlinkHref={value}
            preserveAspectRatio="none"
            width="1"
            height="1"
          />
        </pattern>
      </defs>
      <circle cx={x} cy={y + 25} r="20" fill={`url(#avatar-${name})`} />
    </>
  );
};

const WeeklyTimeSheets = ({ date, office, role, search }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!date) return;
    const fetch = async () => {
      const searchQuery = !search ? '' : `&search=${search}`;
      const roleQuery = role === 'all' ? '' : `&role=${role}`;
      const officeQuery = office === 'all' ? '' : `&office=${office}`;
      const result = await Auth.fetch({
        url: `/admin/time_sheets?date=${date}${searchQuery}${roleQuery}${officeQuery}`,
        method: 'GET',
      });
      setData(result);
    };
    fetch();
  }, [date, office, role, search]);

  const renderTooltip = ({ active, payload }) => {
    if (active && payload) {
      return (
        <div className="timesheet_tooltip">
          <p>
            {payload[0].payload.name}
            <br />
            <span>{payload[0].payload.total_week_time}h</span>
          </p>
        </div>
      );
    }

    return null;
  };
  return (
    <div>
      <div className="row d-flex justify-content-center my-4">
        <div className="col-6 d-flex justify-content-between">
          <span className="weekly-legend under-time">Under Time</span>
          <span className="weekly-legend shift">Shift</span>
          <span className="weekly-legend over-time">Over Time</span>
          <span className="weekly-legend paid-time-off">Paid time off</span>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <BarChart barSize={35} height={450} width={1200} data={data}>
            <CartesianGrid vertical={false} horizontal={false} />
            <XAxis
              dataKey="avatar_url"
              interval={0}
              axisLine={false}
              tickLine={false}
              padding={{ left: 60, right: 40 }}
              tick={Avatar}
              height={100}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              width={1}
              scale="time"
              interval={0}
              tick={<YAxisText />}
              ticks={[0, ...Array.from(Array(50).keys()).map(i => i + 1)]}
            />
            <ReferenceLine y={0} stroke="#ccc" isFront={false} />
            <ReferenceLine y={4.4} stroke="#ccc" isFront={false} />
            <ReferenceLine y={8.8} stroke="#ccc" isFront={false} />
            <ReferenceLine y={13.3} stroke="#ccc" isFront={false} />
            <ReferenceLine y={17.7} stroke="#ccc" isFront={false} />
            <ReferenceLine y={22} stroke="#ccc" isFront={false} />
            <ReferenceLine y={26.4} stroke="#ccc" isFront={false} />
            <ReferenceLine y={30.8} stroke="#ccc" isFront={false} />
            <ReferenceLine y={35.2} stroke="#ccc" isFront={false} />
            <ReferenceLine y={40} stroke="#ccc" isFront={false} />
            <ReferenceLine y={45} stroke="#ccc" isFront={false} />
            <Tooltip content={renderTooltip} />
            <Bar
              dataKey="total_week_time"
              fill="#93D5EB"
              shape={<RoundedRectangle />}
            />
          </BarChart>
        </div>
      </div>
    </div>
  );
};

export default WeeklyTimeSheets;
