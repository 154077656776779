import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button, Badge } from 'shards-react';

const Folders = ({
	data,
	onClick,
	onShare,
	onDelete,
	isShared,
	onPropertiesClick,
	selected,
	onSelect
}) => {
	const [folders, setFolders] = useState([]);
	const [selectedIds, setSelectedIds] = useState([]);

	useEffect(() => {
		if (data.length > 0) {
			setFolders(data);
		}

		if (selected.length !== selectedIds.length) {
			setSelectedIds(selected);
		}
	}, [data, selected]);

	const handleSelect = id => {
		let newSelectedIds = [];
		if (selectedIds.includes(id)) {
			newSelectedIds = selectedIds.filter(itemId => itemId !== id);
		} else {
			newSelectedIds = [...selectedIds, id];
		}

    setSelectedIds(newSelectedIds);
    onSelect.call(null, newSelectedIds);
	};

	const renderFolders = () => {
		return folders.map(folder => (
			<div
				className={`d-flex flex-column flex-sm-row justify-content-between mb-2 hover-gray align-items-center ${selectedIds.includes(
					folder.id
				) && 'bg-light text-dark'}`}
				key={folder.id}
			>
				{!isShared ? (
					<i
						className={`mx-2 lni lni-check-mark-circle ${
							selectedIds.includes(folder.id) ? 'text-success' : 'text-muted'
						}`}
						onClick={() => handleSelect(folder.id)}
					/>
				) : (
					<span className='mx-2 lni lni-check-mark-circle invisible' />
				)}
				<span
					className='col-12 col-sm-6 col-lg-6'
					onClick={() => onClick.call(null, folder.id)}
				>
					<i className={`lni lni-folder mr-2 ${isShared && 'text-info'}`} />
					{folder.attributes.name}
					{isShared && (
						<Badge className='ml-2' theme='info' pill size='sm'>
							Shared
						</Badge>
					)}
				</span>
				<span className='d-none d-md-block col-2 col-lg-1'>-</span>
				<span className='d-none d-md-block col-2'>-</span>
				<span className='col-12 col-sm-6 col-lg-3'>
					{!isShared && (
						<ButtonGroup size='sm'>
							<Button
								title='Share'
								className='d-flex align-items-center'
								onClick={() => onShare.call(null, folder.id)}
								theme='light'
							>
								<i className='lni lni-share' />
							</Button>
							<Button
								title='Delete'
								className='d-flex align-items-center'
								theme='light'
								onClick={() => onDelete.call(null, folder.id)}
							>
								<i className='lni lni-trash' />
							</Button>
							<Button
								title='Properties'
								className='d-flex align-items-center'
								theme='light'
								onClick={() => onPropertiesClick.call(null, folder.id)}
							>
								<i className='lni lni-information' />
							</Button>
						</ButtonGroup>
					)}
				</span>
			</div>
		));
	};

	if (data.length === 0) {
		return null;
	}

	return <>{renderFolders()}</>;
};

Folders.defaultProps = {
	data: [],
	onClick: () => {},
	onDelete: () => {},
	onShare: () => {},
  onPropertiesClick: () => {},
  onSelect: () => {},
  selected: [],
	isShared: false,
};

Folders.propTypes = {
	data: PropTypes.array,
	onClick: PropTypes.func,
	onShare: PropTypes.func,
	onDelete: PropTypes.func,
	onPropertiesClick: PropTypes.func,
  isShared: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.array,
};

export default Folders;
