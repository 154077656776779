import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { Button } from 'shards-react';

const TableData = ({
  data,
  attributes,
  onClick,
  editable,
  deletable,
  onEditClick,
  onDeleteClick,
}) => {
  const defaultValue = 'n/a';

  const renderActions = id => {
    let editBtn;
    let deleteBtn;

    if (editable) {
      editBtn = (
        <Button
          outline
          pill
          theme="info"
          size="sm"
          onClick={() => onEditClick(id)}
          title="Edit"
        >
          <i className="lni lni-pencil-alt"></i>
        </Button>
      );
    }

    if (deletable) {
      deleteBtn = (
        <Button
          outline
          pill
          theme="danger"
          size="sm"
          onClick={() => onDeleteClick(id)}
          title="Delete"
        >
          <i className="lni lni-close"></i>
        </Button>
      );
    }

    if (!editable && !deletable) {
      return null;
    }

    return (
      <td key={`action-${id}`}>
        {editBtn}
        {editable && deletable && <span className="mx-2"></span>}
        {deleteBtn}
      </td>
    );
  };

  const renderCols = (attr, obj, index) => {
    const format = val => {
      if (attr.hasOwnProperty('format') && typeof attr.format === 'function') {
        return attr.format(val);
      }
      return val;
    };

    const value = get(obj, attr.key, attr.defaultValue || defaultValue);

    return <td key={`td-${obj.id}-${index}`}>{format(value)}</td>;
  };

  const renderRows = () =>
    data.map(d => (
      <tr
        key={`tr-${d.id}`}
        className="cursor-pointer"
        onClick={() => onClick(d.id, d)}
      >
        {attributes.map((attr, index) => renderCols(attr, d, index))}
        {renderActions(d.id)}
      </tr>
    ));

  if (!data || !attributes || !Array.isArray(data)) {
    return null;
  }

  return <React.Fragment>{renderRows()}</React.Fragment>;
};

TableData.propTypes = {
  data: PropTypes.array,
  attributes: PropTypes.array,
  onClick: PropTypes.func,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

TableData.defaultProps = {
  data: [],
  attributes: [],
  onClick: () => {},
  editable: false,
  deletable: false,
  onEditClick: () => {},
  onDeleteClick: () => {},
};

export default TableData;
