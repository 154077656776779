class Interval {
	constructor(fn, duration) {
		this.baseline = undefined;
		this.duration = duration;
		this.fn = fn;
	}

	run() {
		if (this.baseline === undefined) {
			this.baseline = new Date().getTime();
		}

		// call the function
		this.fn();
		const end = new Date().getTime();
		this.baseline += this.duration;

		let nextTick = this.duration - (end - this.baseline);
		if (nextTick < 0) {
			nextTick = 0;
    }

		(i => {
			i.timer = setTimeout(() => {
				i.run(end);
			}, nextTick, true);
		})(this);
	}

	stop() {
		clearTimeout(this.timer);
	}
}

export default Interval;
