import React, { Fragment } from 'react';
import { Row, Col } from 'shards-react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from './Form';
import TimelogList from './TimelogList';
import Auth from '../../packs/auth';
import { loadTimelogs } from '.';
import TimelogChart from './TimelogChart';
import Schedule from './Schedule';

const mapDispatch = { loadTimelogs };
class Timelog extends React.Component {
  async componentDidMount() {
    const { data } = await Auth.fetch({
      url: '/timelogs?today=true',
      method: 'GET',
    });
    this.props.loadTimelogs(data);
  }

  render() {
    if (Auth.isPatient()) return <Redirect to="/app/files" />;

    return (
      <Row>
        <Col>
          <Form />
        </Col>
        <Col>
          <TimelogChart />
          <Schedule />
        </Col>
      </Row>
    );
  }
}

export default withRouter(connect(null, mapDispatch)(Timelog));
