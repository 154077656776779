import { toast } from 'react-toastify';

const handleErrors = err => {
  console.warn(err.message);
  if (err.response && err.response.data) {
    if (err.response.data.error) {
      toast.error(err.response.data.error);
      return;
    }

    if (err.response.data.errors) {
      const { detail } = err.response.data.errors[0];
      const Errormessage = Object.keys(detail)
        .map(key => `${key}: ${detail[key].join(', ')}`)
        .join(', ');
      toast.error(Errormessage);
      return;
    }

    toast.error(err.response.data);
  } else {
    toast.error(err.message);
  }
};

export default handleErrors;
