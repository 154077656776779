import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DashBoardLayout from './DashboardLayout';

const AdminRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !!user.email && user.role === 'admin' ? (
        <DashBoardLayout {...props}>
          <Component {...props} />
        </DashBoardLayout>
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

const mapStateToProps = state => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps, null)(AdminRoute));
