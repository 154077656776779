import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Login from './Login';
import Timelog from './timelog/Timelog';
import ProtectedRoute from './ProtectedRoute';

// Shards React
import 'bootstrap/dist/css/bootstrap.min.css';
import 'shards-ui/dist/css/shards.min.css';
import 'react-toastify/dist/ReactToastify.css';

// Default css
import '../css/style.scss';
import store from './store';
import Settings from './settings/Settings';
import Timelogs from './timelogs/Timelogs';
import AdminRoute from './AdminRoute';
import Users from './admin/users/Users';
import Files from './files/Files';
import Register from './Register';
import AdminTimelogs from './admin/timelogs/AdminTimelogs';
import TimelogApproval from './admin/timelogApprovals/TimelogApproval';

// Favicon
import Favicon from '../images/Favicon.png';
import Announcements from './announcements/Announcements';
import ResetPassword from './ResetPassword';
import Schedules from './admin/schedules/Schedules';
import Shift from './admin/shift/Shift';
import TimeSheets from './admin/time-sheets/TimeSheets';

toast.configure();

class App extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>BPDG</title>
          <link rel="icon" type="image/png" href={Favicon} />
          <meta name="description" content="BPDG" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <Provider store={store}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <ProtectedRoute path="/app/files" component={Files} />
              <ProtectedRoute path="/app/dashboard" component={Timelog} />
              <ProtectedRoute path="/app/settings" component={Settings} />
              <ProtectedRoute path="/app/timelogs" component={Timelogs} />
              <ProtectedRoute
                path="/app/announcements"
                component={Announcements}
              />
              <AdminRoute path="/app/users" component={Users} />
              <AdminRoute path="/app/user-timelogs" component={AdminTimelogs} />
              <AdminRoute path="/app/scheduler" component={Schedules} />
              <AdminRoute
                path="/app/user-timelog-approvals"
                component={TimelogApproval}
              />
              <AdminRoute path="/app/shifts" component={Shift} />
              <AdminRoute path="/app/time-sheets" component={TimeSheets} />
            </Switch>
          </BrowserRouter>
        </Provider>
      </>
    );
  }
}

export default App;
