import React, { useEffect, useRef } from 'react';
import consumer from '../../channels/consumer';
import Auth from '../../packs/auth';

const useCable = (callback, channel) => {
	const cachedCallback = useRef();

	const subscribe = channel => {
		consumer.subscriptions.create(
			{
				channel
			},
			{
				connected: function() {
					setTimeout(() => {
						this.perform('follow', { email: Auth.getEmail() });
						console.log('we are live');
					}, 1000);
				},
				received: cachedCallback.current
			}
		);
	};

	useEffect(() => {
		cachedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		if (channel) {
			subscribe(channel);
		}
	}, [channel]);
};

export default useCable;
