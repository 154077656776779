import React, { useState, useEffect } from 'react';
import {
	InputGroup,
	InputGroupAddon,
	FormInput,
	Button,
	InputGroupText
} from 'shards-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useInterval from './hooks/useInterval';
import moment from 'moment';
import { updateTimelog, createLog, destroyLog } from './timelog';
import Auth from '../packs/auth';
import useCable from './hooks/useCable';
import get from 'lodash.get';

const DashboardTimelog = ({
	timelog,
	destroyLog,
	updateTimelog,
	createLog,
	...rest
}) => {
	const [duration, setDuration] = useState(0);
	const [delay, setDelay] = useState(null);
	const [startAt, setStartAt] = useState(moment.utc());

	useEffect(() => {
		if (timelog.id) {
			const timeStart = moment.utc(timelog.attributes.start_at);
			const timeNow = moment.utc();
			setDelay(1000);
			setDuration(
				Math.abs(Math.floor(moment.duration(timeNow - timeStart).asSeconds()))
			);
			setStartAt(timeStart);
		}

		return () => {
			setDelay(null);
			setDuration(0);
		};
	}, [timelog]);

	useInterval(() => {
		const timeNow = moment.utc();
		setDuration(
			Math.abs(Math.floor(moment.duration(timeNow - startAt).asSeconds()))
		);
	}, delay);

	const setTimelogData = data => {
		const endAt = get(data, 'attributes.end_at', '');
		if (!!endAt) {
			destroyLog();
			updateTimelog(data);
			return;
		}

		// the timelog is present skip
		if (timelog.id === data.id) {
			return;
		}

		setStartAt(moment.utc(data.attributes.start_at));

		// the timelog does not exist so create it
		createLog(data);
	};

	useCable(({ data }) => {
		setTimelogData(data);
	}, 'TimerChannel');

	if (!timelog.id) {
		return null;
	}

	const toTime = value => {
		const seconds = value * 1000;
		const result = moment
			.utc(moment.duration(seconds).asMilliseconds())
			.format('HH:mm:ss');
		return result;
	};

	const update = async () => {
		const updatedTimelog = {
			id: timelog.id,
			start_at: timelog.attributes.start_at,
			end_at: new Date(),
			description: timelog.attributes.description,
			duration
		};

		const { data } = await Auth.fetch({
			url: `/timelogs/${timelog.id}`,
			method: 'PUT',
			data: {
				timelog: updatedTimelog
			}
		});

		updateTimelog(data);
		destroyLog();
	};

	return (
		<InputGroup className='mr-2' style={{ width: '190px' }}>
			<InputGroupAddon type='prepend'>
				<InputGroupText>
					<i className='lni lni-timer mr-1'></i>
					{toTime(duration)}
				</InputGroupText>
			</InputGroupAddon>
			<InputGroupAddon type='append'>
				<Button theme='danger' onClick={update}>
					<i className='lni lni-pause'></i>
				</Button>
			</InputGroupAddon>
		</InputGroup>
	);
};

const mapStateToProps = state => ({
	timelog: state.timelog
});

const mapDispatchToProps = {
	destroyLog,
	updateTimelog,
	createLog
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(DashboardTimelog)
);
