import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  FormInput,
  Row,
  Col,
  FormGroup,
} from 'shards-react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import DatePicker from 'react-date-picker';
import Auth from '../../packs/auth';
import {addTimelog, updateTimelog, createLog, destroyLog} from '.';
import Timer from '../Timer';
import handleErrors from '../../packs/handleErrors';

const mapDispatch = {addTimelog, updateTimelog, createLog, destroyLog};
const mapStateToProps = state => ({
  timelog: state.timelog,
  user: state.user,
  timelogs: state.timelogs,
});

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      started: false,
      timelog: {
        description: '',
        start_at: null,
        end_at: null,
        duration: 0,
        log_type: 'shift'
      },
      employee_leave: {
        leave_type: 'sick',
        leave_start: null,
        leave_end: null,
      },
    };
  }

  async componentDidMount() {

    let geoip = "";

    try {

      const resgeo = await fetch('https://geolocation-db.com/json/');
      const geodata = await resgeo.json();
      geoip = geodata.IPv4;

      if(geoip) {

        geoip = " IN: " + geoip;
      }

    } catch (err) {}

    const {destroyLog} = this.props;
    const {data} = await Auth.fetch({
      url: '/timelogs?current=true',
      method: 'GET',
    });
    const self = this;

    if (!data) {
      destroyLog();
    }

    if (data) {
      const {start_at, description, log_type} = data.attributes;
      this.setState(
        {
          timelog: {
            description,
            start_at,
            end_at: null,
            id: data.id,
            duration: 0,
            log_type: log_type || 'shift',
            log_ip: geoip
          },
          started: true,
        },
        () => {
          self.props.createLog(data);
        }
      );
    }
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (Object.keys(nextProps.timelog).length === 0) {
      return {started: false};
    }

    if (nextProps.timelog.attributes.end_at !== null) {
      nextProps.destroyLog();
      return {
        timelog: {
          description: '',
          start_at: null,
          end_at: null,
          duration: 0,
          log_type: 'shift',
        },
        started: false,
      };
    }

    if (!nextState.timelog.id) return {started: false};

    if (nextProps.timelog.id !== nextState.timelog.id) {
      const {start_at, description, log_type} = nextProps.timelog.attributes;

      return {
        timelog: {
          description,
          start_at,
          end_at: null,
          id: nextProps.timelog.id,
          duration: 0,
          log_type,
        },
        started: true,
      };
    }
    return {started: true};
  }

  onBtnGroupClick = description => () => {
    const newState = {...this.state};
    newState.timelog.description = description;
    this.setState(newState);
  };

  handleChange = event => {
    const {name, value} = event.target;
    const newState = {...this.state};
    switch (name) {
      case 'description':
        newState.timelog.description = value;
        this.setState(newState);
        break;
      default:
        return false;
    }
  };

  startBreak = async () => {
    const {timelog} = this.state;
    const {description} = timelog;
    await this.stopShift();
    const self = this;

    this.setState(
      {
        timelog: {
          description,
          log_type: 'break',
          duration: 0,
          start_at: null,
          end_at: null,
        },
      },
      async () => {
        await self.startShift();
      }
    );
  };

  stopBreak = async () => {
    await this.stopShift();
    await this.startShift();
  };

  startShift = async () => {

    let geoip = "";

    try {

      const resgeo = await fetch('https://geolocation-db.com/json/');
      const geodata = await resgeo.json();
      geoip = geodata.IPv4;

      if(geoip) {

        geoip = " IN: " + geoip;
      }

    } catch (err) {}

    const {started, timelog} = this.state;
    const {addTimelog, createLog} = this.props;
    let result;
    if (!timelog.description) {
      toast.error('Please provide a description.');
      return;
    }

    if (started) return;
    const newState = {...this.state};
    newState.timelog.log_ip = geoip;   
    newState.started = true;

    if (!newState.timelog.id) {
      newState.timelog.start_at = new Date().toUTCString();
      const {data} = await Auth.fetch({
        url: '/timelogs',
        method: 'POST',
        data: {timelog: newState.timelog},
      });

      newState.timelog.id = data.id;
      result = data;
    }

    this.setState(newState, async () => {
      if (result) {
        addTimelog(result);
        createLog(result);
      }
    });
  };

  stopShift = async () => {
    const {started, timelog} = this.state;
    const {destroyLog, updateTimelog} = this.props;
    if (!timelog.description) {
      toast.error('Please provide a description.');
      return;
    }

    if (!started) return;
    const newState = {...this.state};
    try {

      const resgeo = await fetch('https://geolocation-db.com/json/');
      const geodata = await resgeo.json();
      const geoip = geodata.IPv4;
      newState.timelog.log_ip += " OUT: " + geoip;
    } catch (err) {}
    newState.started = false;
    newState.timelog.end_at = new Date().toUTCString();
    const {data} = await Auth.fetch({
      url: `/timelogs/${newState.timelog.id}`,
      method: 'PUT',
      data: {timelog: newState.timelog},
    });

    this.setState(
      {
        timelog: {
          description: newState.timelog.description,
          start_at: null,
          end_at: null,
          duration: 0,
          log_type: 'shift',
        },
        started: false,
      },
      () => {
        destroyLog();
        updateTimelog(data);
      }
    );
  };

  onTick = duration => {
    const newState = {...this.state};
    newState.timelog.duration = duration;
    this.setState(newState);
  };

  changeLeaveType = type => () => {
    const newState = {...this.state};
    newState.employee_leave.leave_type = type;
    this.setState(newState);
  };

  renderBtns = () => {
    const {log_type} = this.state.timelog;
    return log_type === 'shift' ? (
      <React.Fragment>
        <Button className="clock-in-btn" onClick={this.startBreak}>
          Take a break
        </Button>
        <Button className="clock-in-btn" onClick={this.stopShift}>
          End my shift
        </Button>
      </React.Fragment>
    ) : (
        <Button className="clock-in-btn" onClick={this.stopBreak}>
          End my break
        </Button>
      );
  };

  handleLeaveFormChange = name => value => {
    const newState = {...this.state};
    newState.employee_leave[name] = value;
    this.setState(newState);
  };

  fileLeave = async () => {
    const {employee_leave} = this.state;

    try {
      await Auth.fetch({
        url: '/employee_leaves',
        method: 'POST',
        data: {employee_leave},
      });
      toast.success('Leave filed!');
    } catch (err) {
      handleErrors(err);
    }
  };

  render() {
    const {timelog, started, employee_leave} = this.state;
    const {user} = this.props;
    const {start_at, log_type} = timelog;
    return (
      <React.Fragment>
        <div style={{maxWidth: 463}}>
          <h1
            className="ibm-plex font-weight-bold display-text-blue"
            style={{fontSize: 36, lineHeight: '47px'}}
          >
            Good Morning, <span>{user.name}</span>!
            <br />
            Welcome back to work.
          </h1>

          <h4 className="inter-font font-weight-bold" style={{fontSize: 18}}>
            {started ? 'Clock out' : 'Clock in'}
          </h4>
          <div
            className="card-border"
            style={{borderRadius: 4, padding: '26px 28px'}}
          >
            <span
              className="ibm-plex font-weight-bold text-black d-block"
              style={{fontSize: 14}}
            >
              {started
                ? log_type === 'shift'
                  ? 'Current Time in Shift'
                  : 'Current Time in Break'
                : 'Start your shift in'}
            </span>

            <div style={{marginTop: 21}}>
              {started ? (
                <Timer
                  onTick={this.onTick}
                  value={start_at}
                  timelogs={this.props.timelogs}
                  logType={log_type}
                />
              ) : (
                  <React.Fragment>
                    <Button
                      outline
                      className="plex-btn"
                      style={{marginRight: 15}}
                      active={timelog.description === 'Arlington'}
                      onClick={this.onBtnGroupClick('Arlington')}
                    >
                      Arlington
                  </Button>
                    <Button
                      outline
                      className="plex-btn"
                      style={{marginRight: 15}}
                      active={timelog.description === 'Cambridge'}
                      onClick={this.onBtnGroupClick('Cambridge')}
                    >
                      Cambridge
                  </Button>
                    <Button
                      outline
                      className="plex-btn"
                      style={{marginRight: 15}}
                      active={timelog.description === 'Lynn'}
                      onClick={this.onBtnGroupClick('Lynn')}
                    >
                      Lynn
                  </Button>
                  </React.Fragment>
                )}
            </div>
            {started ? (
              this.renderBtns()
            ) : (
                <Button className="clock-in-btn" onClick={this.startShift}>
                  Start my shift
                </Button>
              )}
          </div>

          <h4
            className="inter-font font-weight-bold"
            style={{fontSize: 18, marginTop: 35}}
          >
            File for Leave
          </h4>
          <div
            className="card-border"
            style={{borderRadius: 4, padding: '26px 28px'}}
          >
            <span
              className="ibm-plex font-weight-bold text-black d-block"
              style={{fontSize: 14}}
            >
              What kind of leave do you want to file?
            </span>
            <div style={{marginTop: 21}}>
              <Button
                outline
                className="plex-btn"
                style={{marginRight: 15}}
                onClick={this.changeLeaveType('sick')}
                active={employee_leave.leave_type === 'sick'}
              >
                Sick
              </Button>
              <Button
                outline
                className="plex-btn"
                style={{marginRight: 15}}
                onClick={this.changeLeaveType('emergency')}
                active={employee_leave.leave_type === 'emergency'}
              >
                Emergency
              </Button>
              <Button
                outline
                className="plex-btn"
                style={{marginRight: 15}}
                onClick={this.changeLeaveType('parental')}
                active={employee_leave.leave_type === 'parental'}
              >
                Parental
              </Button>
              <Button
                outline
                className="plex-btn"
                style={{marginRight: 15}}
                onClick={this.changeLeaveType('other')}
                active={employee_leave.leave_type === 'other'}
              >
                Other
              </Button>
            </div>
            <span
              className="ibm-plex font-weight-bold text-black d-block"
              style={{fontSize: 14, marginTop: 23}}
            >
              When do you want to file this leave?
            </span>
            <FormGroup className="leave-forms d-flex align-items-center justify-content-between">
              <label htmlFor="#leave_start">Start date</label>
              <DatePicker
                format="y-MM-dd"
                className="form-control"
                name="leave_start"
                id="leave_start"
                onChange={this.handleLeaveFormChange('leave_start')}
                value={employee_leave.leave_start}
              />
            </FormGroup>
            <FormGroup className="leave-forms d-flex align-items-center justify-content-between">
              <label htmlFor="#leave_end">End date</label>
              <DatePicker
                format="y-MM-dd"
                className="form-control"
                id="leave_end"
                name="leave_end"
                onChange={this.handleLeaveFormChange('leave_end')}
                value={employee_leave.leave_end}
              />
            </FormGroup>
            <Button className="clock-in-btn" onClick={this.fileLeave}>
              File for leave
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(Form));
