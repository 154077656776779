import React, {useState, useEffect, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormSelect,
  InputGroup,
  FormInput,
  InputGroupAddon,
  InputGroupText,
  ModalHeader,
  Modal,
  ModalBody,
  FormGroup,
  Button,
} from 'shards-react';
import moment from 'moment-timezone';
import DatePicker from 'react-date-picker';
import Auth from '../../../packs/auth';
import Loader from '../../Loader';
import Table from '../../Table';
import TableData from '../../TableData';
import Paginate from '../../Paginate';
import shortHumanizer from '../../../packs/shortHumanizer';
import TimelogByDate from './TimelogByDate';

const AdminTimelogs = () => {
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [userId, setSelectedUser] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let url = `/admin/timelogs?page=${page}&per_page=${limit}`;
      if (search) {
        url = `/admin/timelogs?page=${page}&per_page=${limit}&search=${search}`;
      }

      const result = await Auth.fetch({
        url,
        method: 'GET',
        getResponseHeaders: headers => {
          setPageCount(parseInt(headers['total-page-count']));
        },
      });

      setData(result);
      setLoading(false);
    };
    setLoading(true);
    fetchData();
  }, [limit, page, search]);

  const handleLimit = ({target}) => {
    setPage(1);
    setLimit(target.value);
  };

  const handlePageChange = value => {
    setPage(value);
  };

  const handleSearch = e => {
    setSearch(e.target.value);
    // reset page number
    setPage(1);
  };

  const toggleModal = () => setOpen(!open);

  const downloadCSV = async () => {
    try {
      const newStart = moment(start).format('YYYY-MM-DD');
      const newEnd = moment(end).format('YYYY-MM-DD');
      const data = await Auth.fetch({
        url: `/admin/download_timelogs?start=${newStart}&end=${newEnd}`,
        method: 'GET',
        responseType: 'blob',
      });

      if (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'timelogs.csv');
        link.setAttribute('target', '_blank');
        link.click();
      }

      setOpen(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  if (selectedDate && userId && selectedEmail) {
    return (
      <TimelogByDate
        date={selectedDate}
        user_id={userId}
        email={selectedEmail}
      />
    );
  }

  return (
    <Fragment>
      <Card>
        <CardHeader className="d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="d-flex flex-column flex-md-row align-items-center">
            Timelogs
            <InputGroup className="ml-3">
              <FormInput
                placeholder="Type to search..."
                onChange={handleSearch}
              />
              <InputGroupAddon type="append">
                <InputGroupText>
                  <i className="lni lni-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>
          <div
            className="d-flex flex-row align-items-center"
            style={{minWidth: 300}}
          >
            <Button onClick={toggleModal}>Export</Button>
            <label className="mx-2 mb-0">Per page</label>
            <FormSelect
              style={{maxWidth: 70}}
              size="sm"
              value={limit}
              onChange={handleLimit}
            >
              {[5, 10, 20, 100].map(i => (
                <option value={i} key={`option-${i}`}>
                  {i}
                </option>
              ))}
            </FormSelect>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {!loading && data.length <= 0 && (
            <h1 className="text-muted">No Records.</h1>
          )}
          {loading && <Loader />}
          {data.length >= 1 && (
            <>
              <Table
                headers={[
                  'User',
                  'Working on?',
                  'Date',
                  'Total Duration',
                  'Time in',
                  'Time out',
                  'IP Address'
                ]}
              >
                <TableData
                  data={data}
                  onClick={(id, data) => {
                    setSelectedDate(data.started_at);
                    setSelectedUser(data.user_id);
                    setSelectedEmail(data.email);
                  }}
                  attributes={[
                    {
                      key: 'email',
                    },
                    {
                      key: 'descriptions',
                      format: values => values.join(', '),
                    },
                    {
                      key: 'started_at',
                    },
                    {
                      key: 'total_duration',
                      format: value => {
                        const seconds = Math.ceil(value) * 1000;
                        return shortHumanizer(seconds);
                      },
                    },
                    {
                      key: 'start_at',
                      format: value => moment(value).format('hh:mm:ss a'),
                    },
                    {
                      key: 'end_at',
                      format: value => value ? moment(value).format('hh:mm:ss a') : "",
                    },
                    {
                      key: 'log_ip'
                    }
                  ]}
                />
              </Table>
            </>
          )}
        </CardBody>
        <CardFooter>
          <Paginate pageCount={pageCount} onChange={handlePageChange} />
          <small className="text-muted">
            Note: <br />
            You can further filter you're search by using{' '}
            <em className="bg-light text-dark">`email, description`</em>, e.g{' '}
            <em className="bg-light text-dark">
              `sample@email.com, arlington`
            </em>{' '}
            this will search the time logs for `sample@email.com` with the
            description of `arlington`.
          </small>
        </CardFooter>
      </Card>
      <Modal open={open} toggle={toggleModal}>
        <ModalHeader>
          Export
          <button
            className="btn position-absolute border-0 bg-white p-2"
            style={{ right: 0, top: 0 }}
            onClick={toggleModal}
          >
            <i className="lni lni-close" />
          </button>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <label htmlFor="#start_date">Start Date</label>
            <DatePicker
              className="form-control"
              id="start_date"
              value={start}
              onChange={date => setStart(date)}
              format="y-MM-dd"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#end_date">End Date</label>
            <DatePicker
              className="form-control"
              id="end_date"
              value={end}
              onChange={date => setEnd(date)}
              format="y-MM-dd"
            />
          </FormGroup>
          <FormGroup>
            <Button onClick={downloadCSV}>Download CSV</Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default withRouter(connect(null, null)(AdminTimelogs));
