import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormSelect,
  Modal,
  Button,
} from 'shards-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import Auth from '../../../packs/auth';
import Table from '../../Table';
import Paginate from '../../Paginate';
import TableData from '../../TableData';
import UserForm from './UserForm';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const { data } = await Auth.fetch({
        url: `/admin/users?page=${page}&per_page=${limit}`,
        method: 'GET',
        getResponseHeaders: headers => {
          setPageCount(parseInt(headers['total-page-count']));
        },
      });
      setUsers(data);
      setLoading(false);
    };

    fetchData();
  }, [limit, page]);

  const handleLimit = ({ target }) => {
    setPage(1);
    setLimit(target.value);
  };

  const toggle = () => setOpen(!open);

  const handlePageChange = value => setPage(value);

  const handleSave = data => {
    const newUsers = [...users];
    newUsers.unshift(data);
    setUsers(newUsers);
    setOpen(false);
    toast.success('User successfuly created.');
  };

  const deleteUser = async id => {
    const result = confirm('Are you sure?');
    if (result) {
      const result = await Auth.fetch({
        url: `/admin/users/${id}`,
        method: 'DELETE',
      });

      if (result.status === 'Success') {
        const newUsers = users.filter(user => user.id !== id);
        setUsers(newUsers);
      }

      if (result.error) {
        console.warn(data.error);
      }
    }
  };

  const handleEdit = id => {
    const filteredUser = users.filter(user => user.id === id);
    if (filteredUser.length > 0) {
      setUser(filteredUser[0]);
      setOpen(true);
    }
  };

  const handleUpdate = data => {
    const updatedUsers = [...users];
    const index = updatedUsers.findIndex(user => user.id === data.id);
    updatedUsers[index] = data;
    setUsers(updatedUsers);
    toast.success('Update success.');
    setOpen(false);
  };

  return (
    <Card>
      <CardHeader className="d-flex flex-row align-items-center justify-content-between">
        <div>
          Users
          <span className="mx-2">|</span>
          <Button size="sm" onClick={toggle}>
            <i className="lni lni-plus mr-1"></i>New
          </Button>
        </div>
        <div
          className="d-flex flex-row align-items-center"
          style={{ minWidth: 150 }}
        >
          <label className="mr-2 mb-0">Per page</label>
          <FormSelect
            style={{ maxWidth: 70 }}
            size="sm"
            value={limit}
            onChange={handleLimit}
          >
            {[5, 10, 20, 100].map(i => (
              <option value={i} key={`option-${i}`}>
                {i}
              </option>
            ))}
          </FormSelect>
        </div>
      </CardHeader>
      <CardBody className="p-0">
        <Table
          headers={[
            'Email',
            'First Name',
            'Last Name',
            'Role',
            'Created At',
            'Actions',
          ]}
        >
          <TableData
            data={users}
            deletable
            editable
            onEditClick={handleEdit}
            onDeleteClick={deleteUser}
            attributes={[
              {
                key: 'attributes.email',
              },
              {
                key: 'attributes.profile.first_name',
              },
              {
                key: 'attributes.profile.last_name',
              },
              {
                key: 'attributes.role',
              },
              {
                key: 'attributes.created_at',
                format: value =>
                  moment.utc(value).format('MMM DD, YYYY @ hh:mm a'),
              },
            ]}
          />
        </Table>
        <Modal open={open} toggle={toggle}>
          <UserForm
            onSave={handleSave}
            data={user}
            onUpdate={handleUpdate}
            toggle={toggle}
          />
        </Modal>
      </CardBody>
      <CardFooter>
        <Paginate pageCount={pageCount} onChange={handlePageChange} />
      </CardFooter>
    </Card>
  );
};

export default Users;
