import React, { useState, useEffect } from 'react';
import moment from 'moment';
import useInterval from './hooks/useInterval';

const Timer = ({ onTick, value, timelogs, logType }) => {
  const [delay, setDelay] = useState(null);
  const [duration, setDuration] = useState(0);
  const [startTime, setStartTime] = useState(moment.utc());

  useEffect(() => {
    if (value) {
      const timeNow = moment.utc();
      const timeStart = moment.utc(value);
      const duration = Math.abs(
        Math.floor(moment.duration(timeNow - timeStart).asSeconds())
      );
      setDelay(1000);
      setStartTime(value);
      setDuration(duration);
    } else {
      setDelay(null);
    }

    return () => {
      setDelay(null);
      setDuration(0);
    };
  }, [value]);

  useInterval(() => {
    const timeNow = moment.utc();
    const timeStart = moment.utc(startTime);
    const newDuration = Math.abs(
      Math.floor(moment.duration(timeNow - timeStart).asSeconds())
    );
    setDuration(newDuration);
    onTick.call(null, newDuration);
  }, delay);

  const toTime = val => {
    const ms = val * 1000;
    let totalMS = ms;

    /*if (logType === 'shift') {
      const prevMs = getPreviousActiveDuration() * 1000;
      totalMS = prevMs + ms;
    }*/
    
    const duration = moment.duration(totalMS);
    let result = moment.utc(duration.asMilliseconds()).format('ss');
    let prefix = 's';
    if (duration.asHours() >= 1) {
      prefix = 'hrs';
      result = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
    } else if (duration.asMinutes() >= 1) {
      prefix = 'min';
      result = moment.utc(duration.asMilliseconds()).format('mm:ss');
    }

    return `${result} ${prefix}`;
  };

  const getPreviousActiveDuration = () => {
    const currentDate = moment().format('YYYY-MM-DD');
    const filtered = timelogs.filter(item => {
      const date = moment(item.attributes.created_at).format('YYYY-MM-DD');
      if (date === currentDate && item.attributes.log_type === 'shift') {
        return item;
      }
    });

    if (filtered.length > 0) {
      const duration = filtered.reduce(
        (sum, item) => (sum += parseFloat(item.attributes.duration)),
        0
      );
      return duration;
    }

    return 0;
  };

  return (
    <span
      className="ibm-plex font-weight-bold text-black"
      style={{ fontSize: 64, lineHeight: '83px' }}
    >
      {toTime(duration)}
    </span>
  );
};

export default Timer;
