import React, { useState, useEffect } from 'react';
import useInterval from './hooks/useInterval';
import moment from 'moment';

const Clock = () => {
	const [time, setTime] = useState(new Date().toLocaleString());
	const [delay, setDelay] = useState(null);

	useEffect(() => {
		setDelay(1000);
		return () => setDelay(null);
	}, []);

	useInterval(() => {
    setTime(new Date().toLocaleString());
	}, delay);

	const toTime = value => {
		const result = moment.utc(value).format('hh:mm a');
		return result;
	};

	return <span>{toTime(time)}</span>;
};

export default Clock;
