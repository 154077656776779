import React, { useState, Fragment, useEffect } from 'react';
import {
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  FormInput,
  ModalFooter,
  Button,
  FormSelect,
} from 'shards-react';
import get from 'lodash.get';
import Auth from '../../../packs/auth';
import handleErrors from '../../../packs/handleErrors';

const UserForm = ({ onSave, data, onUpdate, toggle }) => {
  const roles = ['admin', 'staff', 'patient', 'guest'];
  const [user, setUser] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    role: 'staff',
  });

  useEffect(() => {
    if (data) {
      const newUser = {
        id: data.id,
        email: data.attributes.email,
        first_name: get(data, 'attributes.profile.first_name', ''),
        last_name: get(data, 'attributes.profile.last_name', ''),
        role: data.attributes.role,
        password: '',
      };
      setUser(newUser);
    }
  }, [data]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const newUser = { ...user };
    newUser[name] = value;
    setUser(newUser);
  };

  const save = async () => {
    try {
      const url = user.id ? `/admin/users/${user.id}` : '/admin/users';
      const method = user.id ? 'PUT' : 'POST';
      const userData = {
        email: user.email,
        password: user.password,
        role: user.role,
        profile_attributes: {
          first_name: user.first_name,
          last_name: user.last_name,
        },
      };

      // remove password if empty and user has an id
      if (user.id && !user.password) delete userData.password;

      const { data } = await Auth.fetch({
        url,
        method,
        data: {
          user: userData,
        },
      });

      if (typeof onSave === 'function' && !user.id) {
        onSave.call(null, data);
      }

      if (typeof onUpdate === 'function' && user.id) {
        onUpdate.call(null, data);
      }
    } catch (err) {
      handleErrors(err);
    }
  };

  return (
    <Fragment>
      <ModalHeader>
        <i className="lni lni-user mr-1" />
        {user.id ? 'Update User' : 'New User'}
        <button
          className="btn position-absolute border-0 bg-white p-2"
          style={{ right: 0, top: 0 }}
          onClick={toggle}
        >
          <i className="lni lni-close" />
        </button>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <label htmlFor="#email">Email</label>
            <FormInput
              name="email"
              id="email"
              value={user.email}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#password">Password</label>
            <FormInput
              type="password"
              name="password"
              id="password"
              value={user.password}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#first_name">First Name</label>
            <FormInput
              name="first_name"
              id="first_name"
              value={user.first_name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#last_name">Last Name</label>
            <FormInput
              name="last_name"
              id="last_name"
              value={user.last_name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#role">Role</label>
            <FormSelect value={user.role} name="role" onChange={handleChange}>
              {roles.map(role => (
                <option key={role} value={role.toLowerCase()}>
                  {role}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <FormGroup>
          <Button onClick={save}>
            <i className="lni lni-save mr-1" />
            {user.id ? 'Update' : 'Save'}
          </Button>
        </FormGroup>
      </ModalFooter>
    </Fragment>
  );
};

export default UserForm;
