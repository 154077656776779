import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormSelect,
  Modal,
  ModalHeader,
} from 'shards-react';
import moment from 'moment';
import Auth from '../../packs/auth';
import Loader from '../Loader';
import Table from '../Table';
import TableData from '../TableData';
import Paginate from '../Paginate';
import shortHumanizer from '../../packs/shortHumanizer';
import TimelogModalBody from '../timelog/TimelogModalBody';

const TimelogByDate = ({ date, history }) => {
  const [open, setOpen] = useState(false);
  const [timelog, setTimelog] = useState(null);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await Auth.fetch({
        url: `/timelogs?date=${date}&page=${page}&per_page=${limit}`,
        method: 'GET',
        getResponseHeaders: headers => {
          setPageCount(parseInt(headers['total-page-count']));
        },
      });

      setData(result.data);
      setLoading(null);
    };
    setLoading(true);
    fetchData();
  }, [date, limit, page]);

  const handleLimit = ({ target }) => {
    setPage(1);
    setLimit(target.value);
  };

  const handlePageChange = value => {
    setPage(value);
  };

  if (Auth.isPatient()) return <Redirect to="/app/files" />;

  const onEdit = id => {
    const filtered = data.filter(d => d.id === id);
    if (filtered.length > 0) {
      setOpen(true);
      setTimelog(filtered[0]);
    }
  };

  const onClose = () => {
    setOpen(false);
    setTimelog(null);
  };

  return (
    <Card>
      <CardHeader className="d-flex flex-row align-items-center justify-content-between">
        <button
          type="button"
          onClick={() => location.reload()}
          className="btn btn-primary"
        >
          Back
        </button>
        Timelogs for {moment(date).format('MMM DD, YYYY')}
        <div
          className="d-flex flex-row align-items-center"
          style={{ minWidth: 150 }}
        >
          <label className="mr-2 mb-0">Per page</label>
          <FormSelect
            style={{ maxWidth: 70 }}
            size="sm"
            value={limit}
            onChange={handleLimit}
          >
            {[5, 10, 20, 100].map(i => (
              <option value={i} key={`option-${i}`}>
                {i}
              </option>
            ))}
          </FormSelect>
        </div>
      </CardHeader>
      <CardBody className="p-0">
        {!loading && data.length <= 0 && (
          <h1 className="text-muted">No Records.</h1>
        )}
        {loading && <Loader />}
        {data.length >= 1 && (
          <>
            <Table headers={['Working on?', 'Duration', 'Time in', 'Time out']}>
              <TableData
                data={data}
                editable
                onEditClick={onEdit}
                attributes={[
                  {
                    key: 'attributes.description',
                  },
                  {
                    key: 'attributes.duration',
                    format: value => {
                      const seconds = Math.ceil(value) * 1000;
                      return shortHumanizer(seconds);
                    },
                  },
                  {
                    key: 'attributes.start_at',
                    format: value => moment(value).format('hh:mm:ss a'),
                  },
                  {
                    key: 'attributes.end_at',
                    format: value => value ? moment(value).format('hh:mm:ss a') : "",
                  },
                ]}
              />
            </Table>
          </>
        )}
      </CardBody>
      <CardFooter>
        <Paginate pageCount={pageCount} onChange={handlePageChange} />
      </CardFooter>
      <Modal open={open} toggle={onClose} size="lg">
        <ModalHeader>
          Timelog #{timelog && timelog.id}
          <button
            className="btn position-absolute border-0 bg-white p-2"
            style={{ right: 0, top: 0 }}
            onClick={onClose}
          >
            <i className="lni lni-close" />
          </button>
        </ModalHeader>
        <TimelogModalBody timelog={timelog} onClick={onClose} />
      </Modal>
    </Card>
  );
};

export default withRouter(connect(null, null)(TimelogByDate));
