import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormSelect,
} from 'shards-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import Auth from '../../../packs/auth';
import Loader from '../../Loader';
import Table from '../../Table';
import TableData from '../../TableData';
import Paginate from '../../Paginate';
import shortHumanizer from '../../../packs/shortHumanizer';
import TimelogModalBody from '../../timelog/TimelogModalBody';
import handleErrors from '../../../packs/handleErrors';

const TimelogByDate = ({ date, user_id, email }) => {
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const result = await Auth.fetch({
      url: `/admin/timelogs?date=${date}&user_id=${user_id}&page=${page}&per_page=${limit}`,
      method: 'GET',
      getResponseHeaders: headers => {
        setPageCount(parseInt(headers['total-page-count']));
      },
    });

    setData(result.data);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [fetchData, limit, loading, page]);

  const handleLimit = ({ target }) => {
    setPage(1);
    setLoading(true);
    setLimit(target.value);
  };

  const handlePageChange = value => {
    setPage(value);
    setLoading(true);
  };

  const onDelete = async id => {
    const result = confirm('Are you sure?');
    if (result) {
      try {
        await Auth.fetch({
          url: `/admin/timelogs/${id}`,
          method: 'DELETE',
        });

        toast.success('Successfuly deleted.');
        const newData = data.filter(d => d.id !== id);
        setData(newData);
      } catch (e) {
        handleErrors(e);
      }
    }
  };

  if (Auth.isPatient()) return <Redirect to="/app/files" />;

  return (
    <Card>
      <CardHeader className="d-flex flex-row align-items-center justify-content-between">
        <button
          type="button"
          onClick={() => location.reload()}
          className="btn btn-primary"
        >
          Back
        </button>
        {email} timelogs for {moment(date).format('MMM DD, YYYY')}
        <div
          className="d-flex flex-row align-items-center"
          style={{ minWidth: 150 }}
        >
          <label className="mr-2 mb-0">Per page</label>
          <FormSelect
            style={{ maxWidth: 70 }}
            size="sm"
            value={limit}
            onChange={handleLimit}
          >
            {[5, 10, 20, 100].map(i => (
              <option value={i} key={`option-${i}`}>
                {i}
              </option>
            ))}
          </FormSelect>
        </div>
      </CardHeader>
      <CardBody className="p-0">
        {!loading && data.length <= 0 && (
          <h1 className="text-muted">No Records.</h1>
        )}
        {loading && <Loader />}
        {data.length >= 1 && (
          <>
            <Table headers={['Working on?', 'Duration', 'Time in', 'Time out', 'IP Address']}>
              <TableData
                data={data}
                deletable
                onDeleteClick={onDelete}
                attributes={[
                  {
                    key: 'attributes.description',
                  },
                  {
                    key: 'attributes.duration',
                    format: value => {
                      const seconds = Math.ceil(value) * 1000;
                      return shortHumanizer(seconds);
                    },
                  },
                  {
                    key: 'attributes.start_at',
                    format: value => moment(value).format('hh:mm:ss a'),
                  },
                  {
                    key: 'attributes.end_at',
                    format: value => value ? moment(value).format('hh:mm:ss a') : "",
                  },
                  {
                    key: 'attributes.log_ip'
                  }
                ]}
              />
            </Table>
          </>
        )}
      </CardBody>
      <CardFooter>
        <Paginate pageCount={pageCount} onChange={handlePageChange} />
      </CardFooter>
    </Card>
  );
};

export default withRouter(connect(null, null)(TimelogByDate));
