import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ButtonGroup, Button, Badge } from 'shards-react';

const Documents = ({
	data,
	onClick,
	onShare,
	onDelete,
	onDownload,
	isShared,
	onPropertiesClick,
	onSelect,
	selected
}) => {
	const [files, setFiles] = useState([]);
	const [selectedIds, setSelectedIds] = useState([]);

	useEffect(() => {
		if (data.length > 0) {
			setFiles(data);
		}

		if (selected.length !== selectedIds.length) {
			setSelectedIds(selected);
		}
	}, [data, selected]);

	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
	};

	const getIcon = extension => {
		const images = [
			'bmp',
			'ico',
			'ps',
			'psd',
			'svg',
			'tif',
			'tiff',
			'png',
			'jpeg',
			'gif',
			'jpg'
		];
		const zips = ['zip', '7z', 'rar', 'z', 'pkg'];

		const defaultIcon = 'lni lni-empty-file';
		const zipFileIcon = 'lni lni-zip';
		const imageIcon = 'lni lni-image';

		if (images.includes(extension)) {
			return imageIcon;
		}

		if (zips.includes(extension)) {
			return zipFileIcon;
		}

		return defaultIcon;
	};

	const handleSelect = id => {
		let newSelectedIds = [];
		if (selectedIds.includes(id)) {
			newSelectedIds = selectedIds.filter(itemId => itemId !== id);
		} else {
			newSelectedIds = [...selectedIds, id];
		}

		setSelectedIds(newSelectedIds);
		onSelect.call(null, newSelectedIds);
	};

	const renderDocuments = () => {
		return files.map(file => (
			<div
				className={`d-flex flex-column flex-sm-row justify-content-between mb-2 hover-gray align-items-center ${selectedIds.includes(
					file.id
				) && 'bg-light text-dark'}`}
				key={file.id}
			>
				{!isShared ? (
					<i
						className={`mx-2 lni lni-check-mark-circle ${
							selectedIds.includes(file.id) ? 'text-success' : 'text-muted'
						}`}
						onClick={() => handleSelect(file.id)}
					/>
				) : (
					<span className='mx-2 lni lni-check-mark-circle invisible' />
				)}
				<span
					className='col-12 col-sm-6 col-lg-6'
					onClick={() => onClick.call(null, file.id)}
				>
					<i
						className={`mr-2 ${getIcon(
							file.attributes.file_extension
						)} ${isShared && 'text-info'}`}
					/>
					{file.attributes.filename}
					{isShared && (
						<Badge className='ml-2' pill size='sm' theme='info'>
							Shared
						</Badge>
					)}
				</span>
				<span className='d-none d-md-block col-2 col-lg-1'>
					{formatBytes(file.attributes.file_size)}
				</span>
				<span className='d-none d-md-block col-2'>
					{moment.utc(file.attributes.updated_at).format('DD/MM/YYYY hh:mmA')}
				</span>
				<span className='col-12 col-sm-6 col-lg-3'>
					<ButtonGroup size='sm'>
						{!isShared && (
							<Button
								title='Share'
								className='d-flex align-items-center'
								onClick={() => onShare.call(null, file.id)}
								theme='light'
							>
								<i className='lni lni-share' />
							</Button>
						)}
						<Button
							title='Download'
							className='d-flex align-items-center'
							onClick={() => onDownload.call(null, file.id)}
							theme='light'
						>
							<i className='lni lni-download' />
						</Button>
						{!isShared && (
							<>
								<Button
									title='Delete'
									className='d-flex align-items-center'
									theme='light'
									onClick={() => onDelete.call(null, file.id)}
								>
									<i className='lni lni-trash' />
								</Button>
								<Button
									title='Properties'
									className='d-flex align-items-center'
									theme='light'
									onClick={() => onPropertiesClick.call(null, file.id)}
								>
									<i className='lni lni-information' />
								</Button>
							</>
						)}
					</ButtonGroup>
				</span>
			</div>
		));
	};

	if (data.length === 0) {
		return null;
	}

	return <>{renderDocuments()}</>;
};

Documents.defaultProps = {
	data: [],
	onClick: () => {},
	onDelete: () => {},
	onShare: () => {},
	onDownload: () => {},
	onSelect: () => {},
	onPropertiesClick: () => {},
	selected: [],
	isShared: false
};

Documents.propTypes = {
	data: PropTypes.array,
	onClick: PropTypes.func,
	onShare: PropTypes.func,
	onDelete: PropTypes.func,
	onDownload: PropTypes.func,
	onPropertiesClick: PropTypes.func,
	onSelect: PropTypes.func,
	selected: PropTypes.array,
	isShared: PropTypes.bool
};

export default Documents;
