import storageUtils from "../packs/storageUtils"

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    storageUtils.setItem('state', serializedState)
  } catch {
    //ignored
  }
}

export const loadState = () => {
  try {
    const serializedState = storageUtils.getItem('state')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch(err) {
    return undefined
  }
}
