import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    addUser(state, action) {
      return {...state, ...action.payload}
    },
    removeUser(_state) {
      return {};
    }
  }
})


export const {
  addUser, removeUser
} = userSlice.actions

export default userSlice.reducer
