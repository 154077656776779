import { createSlice } from '@reduxjs/toolkit'

const timelogsSlice = createSlice({
  name: 'timelogs',
  initialState: [],
  reducers: {
    addTimelog(state, action) {
      state.push(action.payload)
    },
    updateTimelog(state, action) {
      const {id} = action.payload
      const index = state.findIndex(t => t.id === id)
      if (index >= 0) {
        state[index] = action.payload
      }
    },
    loadTimelogs(state, action) {
      state.splice(0, state.length)
      state.push(...action.payload)
    }
  }
})

const timelogSlice = createSlice({
  name: 'timelog',
  initialState: {},
  reducers: {
    createLog(_state, action) {
      return action.payload
    },
    destroyLog(_state, action) {
      return {}
    }
  }
})

export const {
  createLog, destroyLog
} = timelogSlice.actions

export const timelogReducer = timelogSlice.reducer

export const {
  addTimelog,
  updateTimelog,
  loadTimelogs,
} = timelogsSlice.actions

export default timelogsSlice.reducer
