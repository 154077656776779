import React from 'react';
import {
	Form,
	FormInput,
	FormGroup,
	Card,
	CardHeader,
	CardBody,
	Button,
	Container,
	CardTitle,
	Alert
} from 'shards-react';
import Axios from 'axios';
import { Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import handleErrors from '../packs/handleErrors';
import { connect } from 'react-redux';

class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: false,
			user: {
				email: '',
				password: '',
				confirm_password: ''
			}
		};
	}

	signup = async e => {
		e.preventDefault();
		const { user } = this.state;
		this.setState({ processing: true });
		try {
			if (user.password !== user.confirm_password) {
				throw new Error('Password and confirm password doest not match.');
			}

			await Axios({
				url: '/signup',
				method: 'POST',
				data: { user }
			});
			toast.success('Registration successful!');
			this.props.history.push('/');
		} catch (err) {
			this.setState({ processing: false });
			handleErrors(err);
		}
	};

	handleChange = event => {
		const { name, value } = event.target;
		const newState = { ...this.state };
		switch (name) {
			case 'email':
				newState.user.email = value;
				this.setState(newState);
				return;
			case 'password':
				newState.user.password = value;
				this.setState(newState);
				return;
			case 'confirm_password':
				newState.user.confirm_password = value;
				this.setState(newState);
				return;
			default:
				return;
		}
	};

	render() {
		const { processing } = this.state;

		if (this.state.user.email) {
			return <Redirect to='/app/dashboard' />;
		}

		return (
			<Container className='d-flex justify-content-center align-items-center min-vh-100'>
				<Card style={{ maxWidth: '300px' }}>
					<CardHeader className='bg-primary text-white'>
						Boston Pediatric
						<br />
						<small className='text-white'>Dental Group</small>
					</CardHeader>
					<CardBody>
						<CardTitle>Register</CardTitle>
						<Form onSubmit={this.signup}>
							<FormGroup>
								<label htmlFor='#email'>Email</label>
								<FormInput
									id='#email'
									placeholder='email'
									type='email'
									onChange={this.handleChange}
									name='email'
								/>
							</FormGroup>
							<FormGroup>
								<label htmlFor='#password'>Password</label>
								<FormInput
									type='password'
									id='#password'
									placeholder='Password'
									onChange={this.handleChange}
									name='password'
								/>
							</FormGroup>
							<FormGroup>
								<label htmlFor='#confirm_password'>Confirm Password</label>
								<FormInput
									id='#confirm_password'
									placeholder='Confirm Password'
									type='password'
									onChange={this.handleChange}
									name='confirm_password'
								/>
							</FormGroup>
							<FormGroup>
								<Button onClick={this.signup}>
									{processing ? (
										<i className='lni lni-spinner lni lni-spin-effect mr-1' />
									) : (
										''
									)}
									Sign Up
								</Button>
							</FormGroup>
						</Form>
					</CardBody>
				</Card>
			</Container>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user
})

export default withRouter(connect(mapStateToProps, null)(Register));
