import React, { useState, useEffect, Fragment } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  FormSelect,
  CardFooter,
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shards-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import Auth from '../../../packs/auth';
import Table from '../../Table';
import Loader from '../../Loader';
import Paginate from '../../Paginate';
import shortHumanizer from '../../../packs/shortHumanizer';

const timelogApproval = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [activeStatus, setActiveStatus] = useState('waiting_approval');
  const [pageCount, setPageCount] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await Auth.fetch({
        url: `/admin/timelog_edit_approvals?page=${page}&per_page=${limit}&status=${activeStatus}`,
        method: 'GET',
        getResponseHeaders: headers => {
          setPageCount(parseInt(headers['total-page-count']));
        },
      });

      setData(data);
      setLoading(false);
    };

    setLoading(true);
    fetchData();
  }, [page, limit, activeStatus]);

  const handleLimit = ({ target }) => {
    setPage(1);
    setLimit(target.value);
  };

  const handlePageChange = value => {
    setPage(value);
  };

  const changeActiveStatus = status => () => setActiveStatus(status);

  const handleClick = id => () => {
    const filtered = data.filter(item => item.id === id);
    setSelectedData(filtered[0]);
  };

  const updateStatus = status => async () => {
    await Auth.fetch({
      url: `/admin/timelog_edit_approvals/${selectedData.id}`,
      method: 'PUT',
      data: {
        timelog_edit_approval: {
          ...selectedData.attributes,
          status,
        },
      },
    });
    const newData = data.filter(item => item.id !== selectedData.id);
    toast.success(`Timelog ${status}.`);
    setData(newData);
    setSelectedData(null);
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            Timelog Change Request
            <ButtonGroup size="sm" className="ml-2">
              <Button
                theme={
                  activeStatus === 'waiting_approval' ? 'primary' : 'secondary'
                }
                onClick={changeActiveStatus('waiting_approval')}
              >
                Waiting Approval
              </Button>
              <Button
                theme={activeStatus === 'approved' ? 'primary' : 'secondary'}
                onClick={changeActiveStatus('approved')}
              >
                Approved
              </Button>
              <Button
                theme={activeStatus === 'denied' ? 'primary' : 'secondary'}
                onClick={changeActiveStatus('denied')}
              >
                Denied
              </Button>
            </ButtonGroup>
          </div>
          <div
            className="d-flex flex-row align-items-center"
            style={{ minWidth: 160 }}
          >
            <label className="mx-2 mb-0">Per page</label>
            <FormSelect
              style={{ maxWidth: 70 }}
              size="sm"
              value={limit}
              onChange={handleLimit}
            >
              {[5, 10, 20, 100].map(i => (
                <option value={i} key={`option-${i}`}>
                  {i}
                </option>
              ))}
            </FormSelect>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {!loading && data.length <= 0 && (
            <h1 className="text-muted">No Records.</h1>
          )}
          {loading && <Loader />}

          {!loading && data.length > 0 && (
            <Table
              headers={[
                'User',
                'Reason',
                'Working on?',
                'Duration',
                'Time in',
                'Time out',
                'Status',
                'Actions',
              ]}
            >
              {data.map(item => (
                <tr key={item.id}>
                  <td>{item.attributes.user}</td>
                  <td>{item.attributes.message}</td>
                  <td>{item.attributes.description}</td>
                  <td>
                    {shortHumanizer(
                      Math.floor(item.attributes.duration * 1000)
                    )}
                  </td>
                  <td>
                    {moment
                      .utc(item.attributes.start_at)
                      .tz('America/New_York')
                      .format('MMM DD, YYYY hh:mm:ss a')}
                  </td>
                  <td>
                    {moment
                      .utc(item.attributes.end_at)
                      .tz('America/New_York')
                      .format('MMM DD, YYYY hh:mm:ss a')}
                  </td>
                  <td>{item.attributes.status}</td>
                  <td>
                    <Button
                      size="sm"
                      theme="light"
                      onClick={handleClick(item.id)}
                    >
                      <i className="lni lni-information" />
                    </Button>
                  </td>
                </tr>
              ))}
            </Table>
          )}
          <Modal
            open={selectedData !== null}
            toggle={() => setSelectedData(null)}
          >
            <ModalHeader>
              Timelog Change Information
              <button
                className="btn position-absolute border-0 bg-white p-2"
                style={{ right: 0, top: 0 }}
                onClick={() => setSelectedData(null)}
              >
                <i className="lni lni-close" />
              </button>
            </ModalHeader>
            {selectedData && (
              <ModalBody>
                <label>User: {selectedData.attributes.user}</label>
                <br />
                <label>Reason</label>
                <p>{selectedData.attributes.message}</p>
                <h3>From</h3>
                <Table
                  headers={['Description', 'Duration', 'Time in', 'Time out']}
                >
                  <tr>
                    <td>{selectedData.attributes.timelog.description}</td>
                    <td>
                      {shortHumanizer(
                        Math.floor(
                          selectedData.attributes.timelog.duration * 1000
                        )
                      )}
                    </td>
                    <td>
                      {moment
                        .utc(selectedData.attributes.timelog.start_at)
                        .tz('America/New_York')
                        .format('MMM DD, YYYY hh:mm:ss a')}
                    </td>
                    <td>
                      {moment
                        .utc(selectedData.attributes.timelog.end_at)
                        .tz('America/New_York')
                        .format('MMM DD, YYYY hh:mm:ss a')}
                    </td>
                  </tr>
                </Table>

                <h3>To</h3>
                <Table
                  headers={['Description', 'Duration', 'Time in', 'Time out']}
                >
                  <tr>
                    <td>{selectedData.attributes.description}</td>
                    <td>
                      {shortHumanizer(
                        Math.floor(selectedData.attributes.duration * 1000)
                      )}
                    </td>
                    <td>
                      {moment
                        .utc(selectedData.attributes.start_at)
                        .tz('America/New_York')
                        .format('MMM DD, YYYY hh:mm:ss a')}
                    </td>
                    <td>
                      {moment
                        .utc(selectedData.attributes.end_at)
                        .tz('America/New_York')
                        .format('MMM DD, YYYY hh:mm:ss a')}
                    </td>
                  </tr>
                </Table>
              </ModalBody>
            )}
            <ModalFooter>
              <ButtonGroup>
                {activeStatus !== 'approved' && (
                  <Button onClick={updateStatus('approved')}>Approve</Button>
                )}
                {activeStatus !== 'denied' && (
                  <Button theme="danger" onClick={updateStatus('denied')}>
                    Deny
                  </Button>
                )}
              </ButtonGroup>
            </ModalFooter>
          </Modal>
        </CardBody>
        <CardFooter>
          <Paginate pageCount={pageCount} onChange={handlePageChange} />
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default timelogApproval;
