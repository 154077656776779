import React, {useState, useEffect, useRef, useCallback} from 'react';
import {FormInput, Button, Modal, ModalBody, Alert} from 'shards-react';
import moment from 'moment';
import {v4 as uuidV4} from 'uuid';
import {toast} from 'react-toastify';
import capitalize from 'lodash.capitalize';
import ScheduleForm from './ScheduleForm';
import Confirm from './Confirm';
import Auth from '../../../packs/auth';
import handleErrors from '../../../packs/handleErrors';

const DEFAULT_SCHED = Object.freeze({
  office: 'arlington',
  role: 'front_desk',
  user_ids: [],
  shifts: [],
  frequency: 'today',
  frequencies: [],
  repeatable: false,
  repeat_weeks_for: 0,
  start_at: moment().toISOString(),
  end_at: moment()
    .endOf('day')
    .toISOString(),
  uid: uuidV4(),
  names: [],
});

const DAYNAMES = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const Schedules = () => {
  const [schedule, setSchedule] = useState(DEFAULT_SCHED);
  const [newSchedules, setNewSchedules] = useState([]);
  const [openNewSchedule, setOpenNewSchedule] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment());
  const [schedules, setSchedules] = useState({});
  const [selectedDate, setSelectedDate] = useState(moment());
  const [filterByOffice, setFilterByOffice] = useState('all');
  const [filterByRole, setFilterByRole] = useState('all');
  const [searchTerm, setSearchTerm] = useState(null);
  const [countWithoutSchedules, setCountWithoutSchedules] = useState(0);
  const [usersWithoutSchedules, setUsersWithoutSchedules] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [week, setWeek] = useState([]);

  const currentWeeks = date => {
    const startWeek = moment(date).startOf('isoWeek');
    const endWeek = moment(date).endOf('isoWeek');
    const wdays = [];
    let day = startWeek;
    while (day <= endWeek) {
      wdays.push(day.format('YYYY-MM-DD'));
      day = day.clone().add(1, 'day');
    }

    setWeek(wdays);
  };

  const fetchData = useCallback(async () => {
    const roleQuery = filterByRole !== 'all' ? `role=${filterByRole}` : '';
    const officeQuery =
      filterByOffice !== 'all' ? `office=${filterByOffice}` : '';
    const dateQuery = currentDate ? `date=${currentDate}` : '';
    const searchQuery = searchTerm ? `search=${searchTerm}` : '';

    const query = [dateQuery, roleQuery, officeQuery, searchQuery]
      .filter(i => !!i)
      .join('&');
    const {data, users_without_schedules, user_names} = await Auth.fetch({
      url: `/admin/schedules${query && `?${query}`}`,
      method: 'GET',
    });

    setSchedules(data);
    setCountWithoutSchedules(users_without_schedules);
    setUsersWithoutSchedules(user_names);
  }, [filterByRole, filterByOffice, currentDate, searchTerm]);

  useEffect(() => {
    const fetchShifts = async () => {
      const {data} = await Auth.fetch({
        url: '/admin/shifts',
        method: 'GET',
      });

      const newShifts = data.map(s => s.attributes);
      setShifts(newShifts);
    };

    fetchShifts();
  }, []);

  useEffect(() => {
    fetchData();
    currentWeeks(currentDate);
  }, [fetchData, filterByOffice, filterByRole, searchTerm, currentDate]);

  const removeFix = () => {
    const {body} = document;
    body.className = '';
  };

  const toggleNewSchedule = () => {
    setSchedule(DEFAULT_SCHED);
    setNewSchedules([]);
    setConfirmed(false);
    setSelectedDate(moment(currentDate).toISOString());
    setOpenNewSchedule(!openNewSchedule);
    removeFix();
  };

  const fixDoubleBars = () => {
    const {body} = document;
    body.className = 'overflow-hidden h-100';
  };

  const onConfirm = newSchedule => {
    const existing = newSchedules.findIndex(
      item => item.uid === newSchedule.uid
    );

    const newSchedulesClone = [...newSchedules];
    if (existing >= 0) {
      newSchedulesClone[existing] = newSchedule;
    } else {
      newSchedulesClone.push(newSchedule);
    }

    setSchedule(newSchedule);
    setNewSchedules(newSchedulesClone);
    setConfirmed(true);
  };

  const addMoreSchedule = () => {
    const newSchedule = {
      ...DEFAULT_SCHED,
    };
    newSchedule.name = schedule.name;
    newSchedule.user_id = schedule.user_id;
    newSchedule.uid = uuidV4();
    newSchedule.shifts = [shifts[0].id.toString()];
    setSchedule(newSchedule);
    setConfirmed(false);
    setIsEditing(false);
  };

  const editSchedule = uid => {
    const found = newSchedules.filter(i => i.uid === uid);
    if (found.length > 0) {
      const editSched = found[0];
      setSchedule(editSched);
      setConfirmed(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const promises = newSchedules.reduce((acc, item) => {
        console.log(item);
        const url = item.id
          ? `/admin/schedules/${item.id}`
          : '/admin/schedules';
        const method = item.id ? 'PUT' : 'POST';
        acc.push(
          Auth.fetch({
            url,
            method,
            data: {
              schedule: {...item},
            },
          })
        );
        return acc;
      }, []);

      await Promise.all(promises);
      setConfirmed(false);
      setSchedule(DEFAULT_SCHED);
      setNewSchedules([]);
      await fetchData();
      setOpenNewSchedule(false);
      removeFix();
      toast.success('Success');
    } catch (e) {
      handleErrors(e);
    }
  };

  const editUserSchedule = (sched, date) => {
    const newSched = {
      ...sched,
      shifts: [sched.shift_id.toString()],
      user_ids: [sched.user_id],
      uid: uuidV4(),
      names: [sched.user.name],
    };

    setSchedule(newSched);
    setSelectedDate(new Date(date).toISOString());
    setIsEditing(true);
    setConfirmed(false);
    setOpenNewSchedule(true);
  };

  const getScheduledUser = (day, timeOfDay, date) => {
    const current = schedules[day.toLowerCase()];
    if (!current) return;
    if (current[timeOfDay] && current[timeOfDay].data) {
      return current[timeOfDay].data
        .filter(i => {
          const res = moment(i.attributes.start_at).diff(moment(date), 'day');
          return res <= 0;
        })
        .map(i => (
          <button
            type="button"
            key={i.id}
            className="schedule-list-avatar cursor-pointer"
            style={{background: `url(${i.attributes.user.avatar})`}}
            title={`${i.attributes.user.email} role ${capitalize(
              i.attributes.role.split('_').join(' ')
            )}`}
            onClick={() => editUserSchedule(i.attributes, date)}
          />
        ));
    }
  };

  const onNext = () => {
    // monday next week
    setCurrentDate(
      moment
        .utc(week[6])
        .add(1, 'day')
        .toISOString()
    );
    setWeek([]);
  };

  const onPrev = () => {
    // Sunday the preview week
    setCurrentDate(
      moment(week[0])
        .subtract(1, 'day')
        .toISOString()
    );
    setWeek([]);
  };

  const selectRole = role => () => {
    setFilterByRole(role);
  };

  const selectOffice = office => () => {
    setFilterByOffice(office);
  };

  const searchName = ({target}) => {
    const {value} = target;
    setSearchTerm(value);
  };

  const toggleNewSchedWithShift = (shift, frequency, date) => e => {
    e.preventDefault();
    const newSched = {...DEFAULT_SCHED};
    newSched.start_at = new Date(date).toISOString();
    newSched.shifts = [shift];
    newSched.frequency = 'today';
    newSched.frequencies = [frequency.toLowerCase()];
    setSchedule(newSched);
    setSelectedDate(new Date(date).toISOString());
    setOpenNewSchedule(!openNewSchedule);
  };

  const clonePreviousWeek = async () => {
    try {
      await Auth.fetch({
        url: `/admin/clone_schedules?date=${moment(
          selectedDate
        ).toISOString()}`,
        method: 'GET',
      });

      toast.success('Successfuly coppied previous week');
      await fetchData();
    } catch (e) {
      toast.error(e.response.data.error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="d-flex align-items-center">
            <div className="admin-schedule-btn-lable" style={{width: 120}}>
              Branches
            </div>
            <button
              className={`admin-schedule-btn ${filterByOffice === 'arlington' &&
                'active'}`}
              type="button"
              onClick={selectOffice('arlington')}
            >
              Arlington
            </button>
            <button
              className={`admin-schedule-btn ${filterByOffice === 'cambridge' &&
                'active'}`}
              type="button"
              onClick={selectOffice('cambridge')}
            >
              Cambridge
            </button>
            <button
              className={`admin-schedule-btn ${filterByOffice === 'lynn' &&
                'active'}`}
              type="button"
              onClick={selectOffice('lynn')}
            >
              Lynn
            </button>
            <button
              className={`admin-schedule-btn ${filterByOffice === 'all' &&
                'active'}`}
              type="button"
              onClick={selectOffice('all')}
            >
              All
            </button>
          </div>
          <div className="d-flex align-items-center" style={{marginTop: 15}}>
            <div className="admin-schedule-btn-lable" style={{width: 120}}>
              Role
            </div>
            <button
              className={`admin-schedule-btn ${filterByRole === 'front_desk' &&
                'active'}`}
              type="button"
              onClick={selectRole('front_desk')}
            >
              Front Desk
            </button>
            <button
              className={`admin-schedule-btn ${filterByRole === 'hygiene' &&
                'active'}`}
              type="button"
              onClick={selectRole('hygiene')}
            >
              Hygiene
            </button>
            <button
              className={`admin-schedule-btn ${filterByRole === 'dentist' &&
                'active'}`}
              type="button"
              onClick={selectRole('dentist')}
            >
              Dentist
            </button>
            <button
              className={`admin-schedule-btn ${filterByRole === 'all' &&
                'active'}`}
              type="button"
              onClick={selectRole('all')}
            >
              All
            </button>
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex align-items-center justify-content-end">
            <div className="admin-schedule-btn-lable" style={{width: 188}}>
              Filter by employee
            </div>
            <FormInput
              name="employee_search"
              className="italic-placeholder"
              placeholder="Search for Employee Name"
              style={{width: 296}}
              onChange={searchName}
            />
          </div>
          <div
            className="d-flex align-items-center justify-content-end"
            style={{marginTop: 15}}
          >
            <Button
              className="text-uppercase inter-font font-weight-bold"
              style={{width: 239, marginRight: 17}}
              onClick={toggleNewSchedule}
            >
              Create a shift
            </Button>

            <Button
              className="text-uppercase inter-font font-weight-bold"
              style={{width: 261}}
              onClick={clonePreviousWeek}
            >
              Copy previous
            </Button>
          </div>
        </div>
      </div>
      <div className="d-flex text-center justify-content-center mt-4 align-items-center">
        <button
          type="button"
          className="mr-4 schedule-page-btn"
          onClick={onPrev}
        >
          Prev
        </button>
        <div className="schedule-date-wrapper">
          {moment(week[0]).format('MMM DD, YYYY')} to{' '}
          {moment(week[6]).format('MMM DD, YYYY')}
        </div>
        <button
          type="button"
          className="ml-4 schedule-page-btn"
          onClick={onNext}
        >
          Next
        </button>
      </div>
      {countWithoutSchedules > 0 && (
        <Alert
          className="my-4 d-flex justify-content-between align-items-center un-scheduled-alert"
          theme="warning"
        >
          <div className="col-10">
            You have {countWithoutSchedules} un-assigned employees for this
            week.
            <br />
            Employees without schedules are &nbsp;
            {usersWithoutSchedules.length > 0 &&
              usersWithoutSchedules.join(', ')}
            .
          </div>
          <button className="col-2" type="button" onClick={toggleNewSchedule}>
            Add to shifts
          </button>
        </Alert>
      )}
      {DAYNAMES.map((day, index) => (
        <div className="schedule-list" key={day}>
          <h5 className="mb-0">{day}</h5>
          <small>{moment(week[index]).format('MMM DD, YYYY')}</small>
          <table className="schedule-list-table">
            <tbody>
              {shifts.map(s => (
                <tr key={s.id}>
                  <td>
                    <span>{s.name}</span>
                    <br />
                    <span>
                      {moment(s.start_time, 'HH:mm').format('hh:mm a')}
                      <i className="mx-1">-</i>
                      {moment(s.end_time, 'HH:mm').format('hh:mm a')}
                    </span>
                  </td>
                  <td>
                    {getScheduledUser(
                      day,
                      s.id.toString(),
                      week[index]
                    )}
                    <button
                      type="button"
                      title="Add schedule"
                      onClick={toggleNewSchedWithShift(
                        s.id.toString(),
                        day,
                        week[index]
                      )}
                      className="add-sched-btn"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
      <Modal
        open={openNewSchedule}
        toggle={toggleNewSchedule}
        className="shifts-modal"
        size="lg"
        showModal={fixDoubleBars}
        hideModal={removeFix}
      >
        <ModalBody>
          {!confirmed && (
            <ScheduleForm
              data={schedule}
              onConfirm={onConfirm}
              selectedDate={selectedDate}
              shifts={shifts}
              isEditing={isEditing}
            />
          )}
          {confirmed && (
            <Confirm
              list={newSchedules}
              onSubmit={handleSubmit}
              onAddShift={addMoreSchedule}
              shifts={shifts}
              editSchedule={editSchedule}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Schedules;
