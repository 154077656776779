import React from 'react';

const Loader = ({ customMessage }) => (
  <div className="d-flex w-100 align-items-center flex-column justify-content-center">
    <i className="lni lni-spinner size-lg lni lni-spin-effect text-muted"></i>
    {customMessage || 'Loading please be patient...'}
  </div>
);

export default Loader;
