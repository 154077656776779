const dataStorage = {};
let hasLocalStorage = false;

if (localStorage) {
  try {
    const x = 'testStorage';
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    hasLocalStorage = true;
  } catch (e) {
    hasLocalStorage = false;
  }
}

class StorageUtils {
  setItem(key, value) {
    if (hasLocalStorage) {
      localStorage.setItem(key, value);
    } else {
      dataStorage[key] = value;
    }
  }

  getItem(key) {
    if (hasLocalStorage) {
      return localStorage.getItem(key);
    } else {
      return dataStorage[key];
    }
  }

  removeItem(key) {
    if (hasLocalStorage) {
      localStorage.removeItem(key);
    } else {
      dataStorage[key] = null;
    }
  }

  clear() {
    if (hasLocalStorage) {
      localStorage.clear();
    } else {
      dataStorage = {}
    }
  }
}

const storageUtils = new StorageUtils();

export default storageUtils;
