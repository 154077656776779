import React, { useState } from 'react';
import Auth from '../../packs/auth';
import handleErrors from '../../packs/handleErrors';
import timeAgo from '../../packs/TimeAgo';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ModalBody, ModalHeader, Modal } from 'shards-react';
import Table from '../Table';
import TableData from '../TableData';

const AnnouncementList = ({ announcement, onEdit, onDelete }) => {
  const [history, setHistory] = useState([]);
  const [open, setOpen] = useState(false);

	const handleEdit = () => onEdit({
    title: announcement.attributes.title,
    body: announcement.attributes.body,
    id: announcement.id,
  });

	const handleDelete = () => onDelete(announcement.id);

	const fetchHistory = async () => {
		try {
			const { data } = await Auth.fetch({
				url: `/announcement_histories?id=${announcement.id}`,
				method: 'GET'
			});

      setHistory(data);
      console.log(data);
      setOpen(true);
		} catch (err) {
			handleErrors(err);
		}
	};

	return (
		<div
			className='shadow p-3 bg-river-city rounded col-12 col-lg-6 mb-2'
			key={announcement.id}
		>
			<div className='d-flex justify-content-between'>
				<h4 className='text-white font-weight-bold'>
					{announcement.attributes.title}
				</h4>
				<span style={{ color: 'rgb(4, 56, 123)' }}>
					{timeAgo.inWords(
						moment.utc(announcement.attributes.created_at).valueOf()
					)}
					{Auth.isAdmin() && (
						<>
							<a onClick={handleEdit} className='cursor-pointer mx-2' title='edit'>
								<i className='lni lni-pencil-alt' />
							</a>
							<a onClick={handleDelete} className='cursor-pointer' title='delete'>
								<i className='lni lni-trash' />
							</a>
						</>
					)}
				</span>
			</div>
			<p className='text-white'>{announcement.attributes.body}</p>
			{Auth.isAdmin() && (
				<a
					className='text-decoration-none cursor-pointer'
          style={{ color: 'rgb(4, 56, 123)', fontSize: 14 }}
          onClick={fetchHistory}
				>
					<i className='lni lni-eye mr-1' />
					Show history
				</a>
			)}
      <Modal open={open} toggle={() => setOpen(false)} size="lg">
        <ModalHeader>
          Announcement Histories
          <button
						className='btn position-absolute border-0 bg-white p-2'
						style={{ right: 0, top: 0 }}
						onClick={() => {
							setOpen(false);
						}}
					>
						<i className='lni lni-close' />
					</button>
        </ModalHeader>
        <ModalBody className='py-2 px-0'>
          <Table headers={[
            'Title',
            'Message',
            'Created At',
            'User'
          ]}>
            <TableData data={history} attributes={[
              {
                key: 'attributes.title'
              },
              {
                key: 'attributes.body'
              },
              {
                key: 'attributes.created_at',
                format: value => {
                  return moment
                    .utc(value)
                    .tz('America/New_York')
                    .format('MMM DD, YYYY hh:mm a');
                }
              },
              {
                key: 'attributes.user'
              }
            ]}/>
          </Table>
        </ModalBody>
      </Modal>
		</div>
	);
};

AnnouncementList.propTypes = {
	announcement: PropTypes.object,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func
};

AnnouncementList.defaultProps = {
	announcement: {},
	onEdit: () => {},
	onDelete: () => {}
};

export default AnnouncementList;
