import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormSelect,
  Modal,
  ModalHeader,
} from 'shards-react';
import moment from 'moment';
import Auth from '../../packs/auth';
import Loader from '../Loader';
import Table from '../Table';
import TableData from '../TableData';
import Paginate from '../Paginate';
import shortHumanizer from '../../packs/shortHumanizer';
import TimelogModalBody from '../timelog/TimelogModalBody';
import TimelogByDate from './TimelogByDate';

const Timelogs = () => {
  const [open, setOpen] = useState(false);
  const [timelog, setTimelog] = useState(null);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await Auth.fetch({
        url: `/timelogs?page=${page}&per_page=${limit}`,
        method: 'GET',
        getResponseHeaders: headers => {
          setPageCount(parseInt(headers['total-page-count']));
        },
      });

      setData(result);
      setLoading(null);
    };

    setLoading(true);
    fetchData();
  }, [limit, page]);

  const handleLimit = ({ target }) => {
    setPage(1);
    setLimit(target.value);
  };

  const handlePageChange = value => {
    setPage(value);
  };

  if (Auth.isPatient()) return <Redirect to="/app/files" />;

  const onClose = () => {
    setOpen(false);
    setTimelog(null);
  };

  if (selectedDate) {
    return <TimelogByDate date={selectedDate} />;
  }

  return (
    <Card>
      <CardHeader className="d-flex flex-row align-items-center justify-content-between">
        Timelogs
        <div
          className="d-flex flex-row align-items-center"
          style={{ minWidth: 150 }}
        >
          <label className="mr-2 mb-0">Per page</label>
          <FormSelect
            style={{ maxWidth: 70 }}
            size="sm"
            value={limit}
            onChange={handleLimit}
          >
            {[5, 10, 20, 100].map(i => (
              <option value={i} key={`option-${i}`}>
                {i}
              </option>
            ))}
          </FormSelect>
        </div>
      </CardHeader>
      <CardBody className="p-0">
        {!loading && data.length <= 0 && (
          <h1 className="text-muted">No Records.</h1>
        )}
        {loading && <Loader />}
        {data.length >= 1 && (
          <>
            <Table
              headers={[
                'Date',
                'Working on?',
                'Total Duration',
                'Time in',
                'Time out'
              ]}
            >
              <TableData
                data={data}
                onClick={(id, data) => {
                  setSelectedDate(data.started_at);
                }}
                attributes={[
                  {
                    key: 'started_at',
                    format: val => moment.utc(val).format('MMM DD, YYYY'),
                  },
                  {
                    key: 'descriptions',
                    format: values => values.join(', '),
                  },
                  {
                    key: 'total_duration',
                    format: value => {
                      const seconds = Math.ceil(value) * 1000;
                      return shortHumanizer(seconds);
                    },
                  },
                  {
                    key: 'start_at',
                    format: value => moment(value).format('MMM DD, YYYY hh:mm:ss a'),
                  },
                  {
                    key: 'end_at',
                    format: value => value ? moment(value).format('MMM DD, YYYY hh:mm:ss a') : "",
                  }
                ]}
              />
            </Table>
          </>
        )}
      </CardBody>
      <CardFooter>
        <Paginate pageCount={pageCount} onChange={handlePageChange} />
      </CardFooter>
      <Modal open={open} toggle={onClose} size="lg">
        <ModalHeader>
          Timelog #{timelog && timelog.id}
          <button
            className="btn position-absolute border-0 bg-white p-2"
            style={{ right: 0, top: 0 }}
            onClick={onClose}
          >
            <i className="lni lni-close" />
          </button>
        </ModalHeader>
        <TimelogModalBody timelog={timelog} onClick={onClose} />
      </Modal>
    </Card>
  );
};

export default withRouter(connect(null, null)(Timelogs));
