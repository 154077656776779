import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Auth from '../../packs/auth';
import { Modal, ModalHeader, ModalBody } from 'shards-react';
import Table from '../Table';
import TableData from '../TableData';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';

const SharingInfo = ({ id, type, show, onClose }) => {
	const [sharingData, setSharingData] = useState([]);
	const [open, setOpen] = useState(false);
	const [forDeletionId, setForDeletionId] = useState(null);

	const fetchData = async () => {
		const { data } = await Auth.fetch({
			url: `/${type}s/${id}/shared_${type}s`,
			method: 'GET'
		});

		setSharingData(data);
	};

	useEffect(() => {
		if (show) {
			fetchData();
			setOpen(show);
			return;
		}

		setOpen(false);
		setSharingData([]);
	}, [id, type, show]);

	const toggle = () => {
		const value = !open;
		setOpen(value);
		onClose.call(null, value);
	};

	const handleConfirmDeletion = async () => {
		try {
			await Auth.fetch({
				url: `/${type}s/${id}/shared_${type}s/${forDeletionId}`,
				method: 'DELETE'
			});
			const newSharingData = sharingData.filter(
				item => item.id !== forDeletionId
			);

			setSharingData(newSharingData);
			setForDeletionId(null);
		} catch (err) {
			toast.error(err.message);
			setForDeletionId(null);
		}
	};

	const showAlert = id => {
		setForDeletionId(id);
	};

	return (
		<>
			<Modal open={open} toggle={toggle}>
				<ModalHeader>Properties</ModalHeader>
				<ModalBody className='px-0'>
					<h5 className='ml-2'>Shared To</h5>
					<Table headers={['Email', 'Actions']}>
						<TableData
							data={sharingData}
							attributes={[
								{
									key: 'attributes.shared_email'
								}
							]}
							deletable
							onDeleteClick={showAlert}
						/>
					</Table>
				</ModalBody>
			</Modal>
			<SweetAlert
				show={forDeletionId > 0}
				warning
				showCancel
				confirmBtnText='Yes, delete it!'
				confirmBtnBsStyle='danger'
				cancelBtnBsStyle='default'
				title='Are you sure?'
				onConfirm={handleConfirmDeletion}
				onCancel={() => {
					setForDeletionId(null);
				}}
				focusCancelBtn
			>
				Are you sure you want to delete this {type}?
			</SweetAlert>
		</>
	);
};

SharingInfo.defaultProps = {
	id: '',
	type: 'folder',
	show: false,
	onClose: () => {}
};

SharingInfo.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	type: PropTypes.string,
	show: PropTypes.bool,
	onClose: PropTypes.func
};

export default SharingInfo;
