import React, { useState, useEffect, useRef } from 'react';
import { FormInput, Button } from 'shards-react';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';
import { toast } from 'react-toastify';
import capitalize from 'lodash.capitalize';
import Auth from '../../../packs/auth';
import handleErrors from '../../../packs/handleErrors';
import WeeklyTimeSheets from './WeeklyTimeSheets';
import DailyTimeSheets from './DailyTimeSheets';

const DAYNAMES = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const TimeSheets = () => {
  const [currentDate, setCurrentDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [filterByOffice, setFilterByOffice] = useState('all');
  const [filterByRole, setFilterByRole] = useState('all');
  const [searchTerm, setSearchTerm] = useState(null);
  const [overview, setOverview] = useState('weekly');
  const days = useRef([]);

  const currentWeeks = date => {
    if (days.current && days.current.length > 0) return;
    const startWeek = moment(date).startOf('isoWeek');
    const endWeek = moment(date).endOf('isoWeek');
    const wdays = [];
    let day = startWeek;
    while (day <= endWeek) {
      wdays.push(day.format('YYYY-MM-DD'));
      day = day.clone().add(1, 'day');
    }

    days.current = wdays;
  };

  useEffect(() => {
    const today = moment();
    currentWeeks(today);
    setCurrentDate(today.format('YYYY-MM-DD'));
  }, []);

  const getReport = async () => {
    const date = moment(currentDate).format('YYYY-MM-DD');
    const query = overview !== 'weekly' ? 'daily=true&' : '';
    const searchQuery = !searchTerm ? '' : `&search=${searchTerm}`;
    const roleQuery = filterByRole === 'all' ? '' : `&role=${filterByRole}`;
    const officeQuery =
      filterByOffice === 'all' ? '' : `&office=${filterByOffice}`;
    const res = await Auth.fetch({
      url: `/admin/time_sheet_reports.xlsx?${query}date=${date}${searchQuery}${roleQuery}${officeQuery}`,
      method: 'GET',
      responseType: 'blob',
    });
    const url = URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${overview}-${date}.xlsx`); // or any other extension
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click'));
  };

  const onNext = () => {
    // monday next week
    let date = new Date(days.current[6]);

    if (overview === 'daily') {
      date = new Date(currentDate);
      const nextDay = moment(date)
        .add(1, 'day')
        .toISOString();
      setCurrentDate(nextDay);
    } else {
      const nextDay = moment(date)
        .add(1, 'day')
        .toISOString();
      setCurrentDate(nextDay);
      days.current = [];
      currentWeeks(nextDay);
    }
  };

  const onPrev = () => {
    // Sunday the preview week
    let date = new Date(days.current[0]);
    if (overview === 'daily') {
      date = new Date(currentDate);
      const prevDay = moment(date)
        .subtract(1, 'day')
        .toISOString();
      setCurrentDate(prevDay);
    } else {
      const prevDay = moment(date)
        .subtract(1, 'day')
        .toISOString();
      setCurrentDate(prevDay);
      days.current = [];
      currentWeeks(prevDay);
    }
  };

  const selectRole = role => () => {
    setFilterByRole(role);
  };

  const selectOffice = office => () => {
    setFilterByOffice(office);
  };

  const searchName = ({ target }) => {
    const { value } = target;
    setSearchTerm(value);
  };

  const changeOverview = value => () => {
    setOverview(value);
    const today = moment();
    currentWeeks(today);
    setCurrentDate(today.format('YYYY-MM-DD'));
  };

  const getDate = () => {
    if (days.current.length <= 0) return;

    if (overview === 'weekly') {
      const start = moment(days.current[0]).format('MMM DD, YYYY');
      const end = moment(days.current[6]).format('MMM DD, YYYY');
      return `${start} to ${end}`;
    }
    return moment(currentDate).format('MMM DD, YYYY');
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="d-flex align-items-center">
            <div className="admin-schedule-btn-lable" style={{ width: 120 }}>
              Branches
            </div>
            <button
              className={`admin-schedule-btn ${filterByOffice === 'arlington' &&
                'active'}`}
              type="button"
              onClick={selectOffice('arlington')}
            >
              Arlington
            </button>
            <button
              className={`admin-schedule-btn ${filterByOffice === 'cambridge' &&
                'active'}`}
              type="button"
              onClick={selectOffice('cambridge')}
            >
              Cambridge
            </button>
            <button
              className={`admin-schedule-btn ${filterByOffice === 'lynn' &&
                'active'}`}
              type="button"
              onClick={selectOffice('lynn')}
            >
              Lynn
            </button>
            <button
              className={`admin-schedule-btn ${filterByOffice === 'all' &&
                'active'}`}
              type="button"
              onClick={selectOffice('all')}
            >
              All
            </button>
          </div>
          <div className="d-flex align-items-center" style={{ marginTop: 15 }}>
            <div className="admin-schedule-btn-lable" style={{ width: 120 }}>
              Role
            </div>
            <button
              className={`admin-schedule-btn ${filterByRole === 'front_desk' &&
                'active'}`}
              type="button"
              onClick={selectRole('front_desk')}
            >
              Front Desk
            </button>
            <button
              className={`admin-schedule-btn ${filterByRole === 'hygiene' &&
                'active'}`}
              type="button"
              onClick={selectRole('hygiene')}
            >
              Hygiene
            </button>
            <button
              className={`admin-schedule-btn ${filterByRole === 'dentist' &&
                'active'}`}
              type="button"
              onClick={selectRole('dentist')}
            >
              Dentist
            </button>
            <button
              className={`admin-schedule-btn ${filterByRole === 'all' &&
                'active'}`}
              type="button"
              onClick={selectRole('all')}
            >
              All
            </button>
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex align-items-center justify-content-end">
            <div className="admin-schedule-btn-lable" style={{ width: 188 }}>
              Filter by employee
            </div>
            <FormInput
              name="employee_search"
              className="italic-placeholder"
              placeholder="Search for Employee Name"
              style={{ width: 296 }}
              onChange={searchName}
            />
          </div>
          <div
            className="d-flex align-items-center justify-content-end"
            style={{ marginTop: 15 }}
          >
            <Button
              className="text-uppercase inter-font font-weight-bold"
              style={{ width: 296 }}
              onClick={getReport}
            >
              Export Excel
            </Button>
          </div>
        </div>
      </div>
      <div>
        <button
          className={`overview-btn ${overview === 'weekly' && 'active'}`}
          type="button"
          onClick={changeOverview('weekly')}
        >
          Weekly Overview
        </button>
        <button
          className={`overview-btn ${overview === 'daily' && 'active'}`}
          type="button"
          onClick={changeOverview('daily')}
        >
          Daily Overview
        </button>
      </div>
      <div className="d-flex text-center justify-content-center mt-4 align-items-center">
        <button
          type="button"
          className="mr-4 schedule-page-btn"
          onClick={onPrev}
        >
          Prev
        </button>
        <div className="schedule-date-wrapper">{getDate()}</div>
        <button
          type="button"
          className="ml-4 schedule-page-btn"
          onClick={onNext}
        >
          Next
        </button>
      </div>
      {overview === 'weekly' && (
        <WeeklyTimeSheets
          date={days.current[0]}
          office={filterByOffice}
          role={filterByRole}
          search={searchTerm}
        />
      )}
      {overview === 'daily' && (
        <DailyTimeSheets
          date={currentDate}
          office={filterByOffice}
          role={filterByRole}
          search={searchTerm}
        />
      )}
    </>
  );
};

export default TimeSheets;
