import React, { useState, useEffect, useCallback } from 'react';
import get from 'lodash.get';
import Auth from '../packs/auth';

const SearchInput = ({
  url,
  name,
  id,
  value,
  dataKey,
  displayKey,
  format,
  limit = 5,
  onChange,
  placeholder,
  inputClassName,
  clearOnComplete,
  disabled = false,
}) => {
  const [data, setData] = useState([]);
  const [term, setTerm] = useState('');
  const [fetch, setFetch] = useState(true);
  const [displayedValue, setDisplayedValue] = useState('');

  const getData = useCallback(item => {
    if (displayKey) {
      return get(item, displayKey, '');
    }

    if (typeof format === 'function') {
      return format.call(null, item);
    }
  });

  useEffect(() => {
    if (!value) return;
    const fetchData = async () => {
      const result = await Auth.fetch({
        url: `${url}/${value}`,
        method: 'GET',
      });

      setDisplayedValue(getData(result.data));
    };
    fetchData();
  }, [getData, url, value]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await Auth.fetch({
        url: `${url}?limit=${limit}&search=${term}`,
        method: 'GET',
      });
      setData(result.data);
    };

    if (fetch) fetchData();
  }, [url, term, limit, fetch]);

  const handleSearch = ({ target }) => {
    setDisplayedValue('');
    setTerm(target.value);
    if (!fetch) setFetch(true);
  };

  const onSelect = (id, val) => () => {
    setDisplayedValue(clearOnComplete ? '' : val);
    setTerm('');
    setFetch(false);
    setData([]);
    onChange.call(null, {
      target: {
        name,
        value: id,
        valueName: val,
      },
    });
  };

  return (
    <div className="search-input">
      <input
        type="text"
        value={displayedValue || term}
        className={`form-control ${inputClassName}`}
        placeholder={placeholder}
        onChange={handleSearch}
        disabled={disabled}
      />
      {data.length > 0 && (
        <ul className="search-list-data">
          {data.map(item => (
            <li key={item.id}>
              <a role="link" onClick={onSelect(item.id, getData(item))}>
                {getData(item)}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchInput;
