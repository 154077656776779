import React, { useState, useEffect } from 'react';
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Button,
	Form,
	FormGroup,
	FormInput,
	FormSelect,
	Row,
	Col
} from 'shards-react';
import Auth from '../../packs/auth';
import storageUtils from '../../packs/storageUtils';

const Profile = () => {
	const [avatarUrl, setAvatar] = useState('');
	const [profile, setProfile] = useState({
		first_name: '',
		last_name: '',
		middle_name: '',
		gender: ''
	});
	const [file, setFile] = useState(null);

	const fetchData = async () => {
		const { data } = await Auth.fetch({
			url: '/profiles',
			method: 'GET'
		});

		if (data) {
			const {
				first_name,
				last_name,
				gender,
				avatar,
				middle_name
			} = data.attributes;
			setProfile({
				id: data.id,
				first_name: first_name || '',
				last_name: last_name || '',
				middle_name: middle_name || '',
				gender: gender || ''
			});
			if (avatar) setAvatar(avatar);
		}
	};

	useEffect(() => {
		const imgAvatar = storageUtils.getItem('avatarUrl');
		if (imgAvatar) {
			setAvatar(imgAvatar);
		}
		fetchData();
	}, []);

	const handleChange = ({ target }) => {
		const { name, value } = target;
		const newProfile = { ...profile };
		newProfile[name] = value;
		setProfile(newProfile);
	};

	const save = async () => {
		const formData = new FormData();
		formData.append('profile[first_name]', profile.first_name);
		formData.append('profile[middle_name]', profile.middle_name);
		formData.append('profile[last_name]', profile.last_name);
		formData.append('profile[gender]', profile.gender);
		formData.append('profile[avatar]', file || '');

		const url = !profile.id ? '/profiles' : `/profiles/${profile.id}`;
		const method = profile.id ? 'PUT' : 'POST';
		try {
			const { data } = await Auth.fetch({
				url,
				method,
				data: formData
			});

			const {
				first_name,
				last_name,
				gender,
				avatar,
				middle_name
			} = data.attributes;
			setProfile({
				id: data.id,
				first_name: first_name || '',
				last_name: last_name || '',
				middle_name: middle_name || '',
				gender: gender || ''
			});
			if (avatar) {
				setAvatar(`${location.protocol}//${location.hostname}${avatar}`);
			}
			toast.success('Profile has been update...');
		} catch (err) {
			toast.warning('Something went wrong');
		}
	};

	const addFile = ({ target }) => {
		const { files } = target;
		const reader = new FileReader();
		setFile(files[0]);

		reader.onload = e => {
			setAvatar(e.target.result);
		};

		reader.readAsDataURL(files[0]);
	};

	return (
		<Form>
			<h3>Profile</h3>
			<Row>
				<Col>
					<FormGroup>
						<label htmlFor='#first_name'>First Name</label>
						<FormInput
							id='first_name'
							name='first_name'
							onChange={handleChange}
							value={profile.first_name}
						/>
					</FormGroup>
					<FormGroup className='col-6 px-0'>
						<label htmlFor='#middle_name'>Middle Name</label>
						<FormInput
							id='middle_name'
							name='middle_name'
							onChange={handleChange}
							value={profile.middle_name}
						/>
					</FormGroup>
					<FormGroup>
						<label htmlFor='#last_name'>Last Name</label>
						<FormInput
							id='last_name'
							name='last_name'
							onChange={handleChange}
							value={profile.last_name}
						/>
					</FormGroup>
					<FormGroup className='col-6 px-0'>
						<label htmlFor='#gender'>Gender</label>
						<FormSelect
							id='gender'
							name='gender'
							onChange={handleChange}
							value={profile.gender}
						>
							<option></option>
							<option value='male'>Male</option>
							<option value='female'>Female</option>
						</FormSelect>
					</FormGroup>
				</Col>
				<Col className='d-flex flex-column align-items-center'>
					<label>Avatar</label>
					<div className='rounded-lg p-4 bg-light mb-2'>
						<img
							src={avatarUrl}
							className='rounded-circle border'
							width={100}
							height={100}
						/>
					</div>
					<input
						type='file'
						name='avatar'
						className='d-none'
						id='#avatar'
						onChange={addFile}
						accept='image/*'
					/>
					<label className='btn btn-primary' htmlFor='#avatar'>
						Upload
					</label>
				</Col>
			</Row>
			<Button onClick={save}>Update / Save</Button>
		</Form>
	);
};

export default Profile;
