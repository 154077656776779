import { combineReducers } from '@reduxjs/toolkit';

import timelogsReducer, { timelogReducer } from './timelog';
import announcementsReducer from './announcements';
import userReducer from './auth';

const rootReducer = combineReducers({
	timelogs: timelogsReducer,
	timelog: timelogReducer,
	user: userReducer,
	announcements: announcementsReducer
});

export default rootReducer;
