import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'shards-react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import truncate from 'lodash.truncate';
import Logo from '../images/logo-sm.png';
import Auth from '../packs/auth';
import DashboardTimelog from './DashboardTimelog';
import ModalNav from './ModalNav';
import SideBar from './SideBar';
import Notifications from './Notifications';
import storageUtils from '../packs/storageUtils';
import { loadAnnouncements } from './announcements';
import useCable from './hooks/useCable';
import { removeUser } from './auth';
import NavigationBar from './NavigationBar';

const ONE_DAY = 1000 * 60 * 60 * 24;
const THREE_MINUTES = 1000 * 60 * 3;
const { NODE_ENV } = process.env;

const DashBoardLayout = ({
  loadAnnouncements,
  history,
  location,
  children,
  user,
  removeUser,
}) => {
  const timer = useRef();
  const [avatarUrl, setAvatarUrl] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mainNavOpen, setMainNavOpen] = useState(false);
  const [openOverlay, setOpeOverlay] = useState(false);
  const [notifications, setNotifications] = useState({});
  const defaultTimeout = NODE_ENV === 'production' ? THREE_MINUTES : ONE_DAY; // 3minutes

  const fetchAnnouncements = useCallback(async () => {
    const { data } = await Auth.fetch({
      url: '/announcements',
      method: 'GET',
    });
    loadAnnouncements(data);
  }, [loadAnnouncements]);

  const authenticate = useCallback(async () => {
    const isVerified = await Auth.verify();
    if (!isVerified) {
      await Auth.logout();
      removeUser();
      history.push('/');
    }

    const newAvatarUrl = storageUtils.getItem('avatarUrl');
    setAvatarUrl(newAvatarUrl);

    if (user.role === 'admin' || user.role === 'staff') {
      await fetchAnnouncements();
    }
  }, [fetchAnnouncements, history, removeUser, user.role]);

  const onReceived = data => {
    toast.info(
      <div>
        <strong>{data.title}</strong>
        <br />
        <span>{truncate(data.body, { length: 40 })}</span>
      </div>
    );
    fetchAnnouncements();
  };

  useCable(onReceived, 'AnnouncementChannel');

  useEffect(() => {
    const fetchNotifications = async () => {
      const result = await Auth.fetch({
        url: '/admin/notifications',
        method: 'GET',
      });

      setNotifications(result);
    };
    authenticate();
    fetchNotifications();
  }, [authenticate]);

  const toggleMainNavbar = () => {
    setMainNavOpen(!mainNavOpen);
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const logout = async () => {
    setDropdownOpen(false);
    await Auth.logout();
    removeUser();
    history.push('/');
  };

  const toggleOverlayMenu = () => {
    setOpeOverlay(!openOverlay);
  };

  const onAction = () => {
    console.log('user did something');
  };

  const onActive = () => {
    console.log('user is active');
  };

  const onIdle = () => {
    console.log('user is idle');
    timer.current.reset();
    Auth.removeAuth();
    removeUser();
    history.push('/');
  };

  return (
    <React.Fragment>
      <IdleTimer
        ref={timer}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={defaultTimeout}
      />
      <Navbar theme="white" expand="md" className="shadow-blue">
        <NavbarBrand>
          <img src={Logo} alt="Boston pediatric dental group" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleOverlayMenu}>
          <i className="lni lni-menu" />
        </NavbarToggler>
        <Collapse open={mainNavOpen} navbar className="d-none d-md-block">
          <Nav navbar className="ml-auto">
            <NavItem>{/* <DashboardTimelog /> */}</NavItem>
            <NavItem className="d-flex flex-column justify-content-center">
              <Notifications />
            </NavItem>
            <NavItem className="d-flex align-items-center d-lg-none">
              <NavLink onClick={toggleOverlayMenu} className="cursor-pointer">
                <i className="lni lni-menu" />
              </NavLink>
            </NavItem>
            <Dropdown open={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle nav className="text-dark">
                {avatarUrl ? (
                  <img
                    src={avatarUrl}
                    title={user.email}
                    className="img-responsive rounded-circle"
                    width={40}
                    height={40}
                    alt={user.email}
                  />
                ) : (
                  user.email
                )}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => history.push('/app/settings')}>
                  <i className="lni lni-cog mr-1" />
                  Settings
                </DropdownItem>
                <DropdownItem onClick={() => logout()}>
                  <i className="lni lni-exit mr-1" />
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
      </Navbar>
      <NavigationBar
        isAdmin={user.role === 'admin'}
        pathname={location.pathname}
        notifications={notifications}
      />
      <div
        className="container-fluid"
        style={{ minHeight: 'calc(100vh - 83px)', maxWidth: '1300px' }}
      >
        <div className="p-md-4 p-4 main-content">{children}</div>
      </div>
      <ModalNav
        open={openOverlay}
        toggle={toggleOverlayMenu}
        isAdmin={user.role === 'admin'}
        isStaff={user.role === 'staff'}
        isPatient={user.role === 'patient'}
        isGuest={user.role === 'guest'}
        history={history}
        notifications={notifications}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, {
    loadAnnouncements,
    removeUser,
  })(DashBoardLayout)
);
