import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'shards-react';

const NavigationBar = ({ isAdmin, pathname, notifications }) => {
  const [active, setActive] = useState(null);
  const [open, setOpen] = useState(false);
  const [access, setAccess] = useState(['everyone']);
  const navItems = [
    { name: 'Dashboard', path: '/app/dashboard', access: 'everyone' },
    { name: 'My Timelogs', path: '/app/timelogs', access: 'everyone' },
    { name: 'Announcements', path: '/app/announcements', access: 'everyone' },
    { name: 'Scheduler', path: '/app/scheduler', access: 'admin' },
    { name: 'Settings', path: '/app/settings', access: 'everyone' },
    { name: 'Shifts', path: '/app/shifts', access: 'admin' },
    { name: 'Time Sheets', path: '/app/time-sheets', access: 'admin' },
    { name: 'Timelogs', path: '/app/user-timelogs', access: 'admin' },
    {
      name: 'Update Request',
      path: '/app/user-timelog-approvals',
      access: 'admin',
    },
    { name: 'Users', path: '/app/users', access: 'admin' },
  ];

  useEffect(() => {
    if (isAdmin && !access.includes('admin')) {
      const clone = [...access];
      clone.push('admin');
      setAccess(clone);
    }
  }, [access, isAdmin]);

  const toggleNav = () => setOpen(!open);

  const renderNavItems = () => {
    const filtered = navItems.filter(n => access.includes(n.access));
    return filtered.map(n => (
      <NavItem key={n.name}>
        <NavLink active={pathname === n.path} href={n.path}>
          {n.name} {n.name === 'Update Request' && displayNumberOfUpdateRequest()}
        </NavLink>
      </NavItem>
    ));
  };

  const displayNumberOfUpdateRequest = () => {
    if (Object.keys(notifications).length <= 0) return null;
    const { update_request } = notifications;
    return (
      <span className="badge badge-pill badge-danger  text-white">
        {update_request.data.length}
      </span>
    );
  };

  return (
    <Navbar type="dark" theme="primary" expand="md" className="main-navbar">
      <NavbarToggler onClick={toggleNav} />
      <Collapse open={open} navbar>
        <Nav navbar>{renderNavItems()}</Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavigationBar;
