import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Auth from '../packs/auth';
import { removeUser } from './auth';

const ModalNav = ({
  open,
  toggle,
  isAdmin,
  isStaff,
  isPatient,
  isGuest,
  history,
  removeUser,
  notifications,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(open);
  }, [open]);

  const toggleMenu = () => {
    setShow(!show);
    toggle.call(null);
  };

  const logout = async () => {
    await Auth.logout();
    removeUser();
    history.push('/');
  };

  const displayNumberOfUpdateRequest = () => {
    if (Object.keys(notifications).length <= 0) return null;
    const { update_request } = notifications;

    if (update_request.data.length <= 0) return null;
    return (
      <span className="badge badge-pill badge-danger  text-white">
        {update_request.data.length}
      </span>
    );
  };

  return (
    <div
      className="overlay"
      style={{
        width: show ? '100%' : 0,
      }}
    >
      <a className="closebtn cursor-pointer" onClick={toggleMenu}>
        &times;
      </a>
      {(isStaff || isAdmin) && (
        <div className="overlay-content">
          <Link to="/app/announcements" onClick={toggleMenu}>
            Announcements
          </Link>
          <Link to="/app/dashboard" onClick={toggleMenu}>
            Dashboard
          </Link>
          <Link to={Auth.getPatientPortalSSO()} className="cursor-pointer">
            Files
          </Link>
          <Link to="/app/timelogs" onClick={toggleMenu}>
            Timelog Report
          </Link>
          {isAdmin && (
            <>
              <Link to="/app/users" onClick={toggleMenu}>
                Users
              </Link>
              <Link to="/app/user-timelogs" onClick={toggleMenu}>
                User Timelogs
              </Link>
              <Link to="/app/user-timelog-approvals" onClick={toggleMenu}>
                Update Request {displayNumberOfUpdateRequest()}
              </Link>
            </>
          )}
          <Link to="/app/settings" onClick={toggleMenu}>
            Settings
          </Link>
          <Link to="#" onClick={logout}>
            Logout
          </Link>
        </div>
      )}

      {(isPatient || isGuest) && (
        <div className="overlay-content">
          <Link to="/app/files" onClick={toggleMenu}>
            Files
          </Link>
          <Link to="/app/settings" onClick={toggleMenu}>
            Settings
          </Link>
        </div>
      )}
    </div>
  );
};

ModalNav.propTypes = {
  open: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isStaff: PropTypes.bool,
  isPatient: PropTypes.bool,
  isGuest: PropTypes.bool,
  toggle: PropTypes.func,
  notifications: PropTypes.object,
};

ModalNav.defaultProps = {
  open: false,
  isAdmin: false,
  isStaff: false,
  isPatient: false,
  isGuest: false,
  toggle: () => {},
  notifications: {},
};

export default withRouter(
  connect(
    null,
    {
      removeUser,
    }
  )(ModalNav)
);
