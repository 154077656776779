import { configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './localStore';

import rootReducer from './rootReducer';

const preloadedState = loadState();
const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

store.subscribe(() => {
  saveState({
    timelog: store.getState().timelog,
    user: store.getState().user,
  });
});

export default store;
