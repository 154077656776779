import React from 'react'

const Table = ({headers, ...rest}) => (
  <table className='table table-hover table-responsive-sm'>
    <thead>
      <tr>
        {headers.map(h => (
          <th key={`table-${h.replace(/ /g, '-')}`}>
            <small className='text-muted'>{h}</small>
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rest.children}
    </tbody>
  </table>
)

export default Table;
