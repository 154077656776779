import { createSlice } from '@reduxjs/toolkit'

const announcementsSlice = createSlice({
  name: 'announcements',
  initialState: [],
  reducers: {
    addAnnouncement(state, action) {
      state.unshift(action.payload)
    },
    updateAnnouncement(state, action) {
      const {id} = action.payload
      const index = state.findIndex(t => t.id === id)
      if (index >= 0) {
        state[index] = action.payload
      }
    },
    deleteAnnouncement(state, action) {
      return state.filter(item => item.id !== action.payload.id)
    },
    loadAnnouncements(state, action) {
      state.splice(0, state.length)
      state.push(...action.payload)
    }
  }
})

export const {
  addAnnouncement,
  updateAnnouncement,
  loadAnnouncements,
  deleteAnnouncement
} = announcementsSlice.actions

export default announcementsSlice.reducer
