import React, { useEffect, useState, useRef } from 'react';
import {
  InputGroup,
  InputGroupAddon,
  FormInput,
  InputGroupText,
  Button,
} from 'shards-react';

const Paginate = ({ pageCount, onChange }) => {
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const maxVisible = 10;

  useEffect(() => {
    if (pageCount > 1) {
      setPages(Array.from(Array(pageCount).keys()).map(i => i + 1));
      setDisableNext(false);
    } else {
      setPages([]);
      setDisableNext(true);
    }
  }, [pageCount]);

  useEffect(() => {
    onChange(currentPage);
  }, [currentPage, onChange]);

  const handleChange = ({ target }) => {
    setCurrentPage(target.value);
    setDisableNext(target.value >= pages.length);
    setDisablePrev(target.value <= 1);
  };

  const onPrev = () => {
    const current = currentPage - 1;
    setCurrentPage(current <= 0 ? 0 : current);
    setDisablePrev(current <= 1);
    setDisableNext(current >= pages.length);
  };

  const onNext = () => {
    const current = currentPage + 1;
    const lastPage = pages.length;
    setCurrentPage(current >= lastPage ? lastPage : current);
    setDisableNext(current >= lastPage);
    setDisablePrev(current <= 1);
  };

  const showForm = () => {
    setIsEditing(true);
  };

  const hideForm = () => {
    setIsEditing(false);
  };

  if (pages.length <= 1) return null;

  return (
    <div style={{ maxWidth: 200 }}>
      <InputGroup size="sm">
        <InputGroupAddon type="prepend">
          <Button theme="primary" onClick={onPrev} disabled={disablePrev}>
            Prev
          </Button>
        </InputGroupAddon>
        {isEditing && (
          <FormInput
            value={currentPage}
            onChange={handleChange}
            onBlur={hideForm}
            autoFocus
            className="py-3"
          />
        )}
        <InputGroupAddon type="append" style={{ marginLeft: `1px` }}>
          {!isEditing && (
            <InputGroupText
              className="text-black font-weight-bold bg-white"
              onClick={showForm}
            >
              {currentPage}
            </InputGroupText>
          )}
          <InputGroupText>{pages.length}</InputGroupText>
        </InputGroupAddon>
        <InputGroupAddon type="append">
          <Button theme="primary" onClick={onNext} disabled={disableNext}>
            Next
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
};

export default Paginate;
