import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button,
  Alert,
  CardFooter,
  Row,
  Col,
} from 'shards-react';
import { toast } from 'react-toastify';
import Auth from '../../packs/auth';
import Profile from './Profile';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      user: {
        id: null,
        email: '',
        password: '',
        confirm_password: '',
      },
    };
  }

  async componentDidMount() {
    const { data } = await Auth.fetch({
      url: '/users',
      method: 'GET',
    });

    this.setState({
      user: {
        id: data.id,
        email: data.attributes.email,
        password: '',
        confirm_password: '',
      },
    });
  }

  handleChange = event => {
    const { name, value } = event.target;
    const newState = { ...this.state };
    newState.user[name] = value;
    this.setState(newState);
  };

  update = async () => {
    this.setState({ errors: [] });
    try {
      const { user } = this.state;
      const { id } = user;
      const { data } = await Auth.fetch({
        url: `/users/${id}`,
        method: 'PUT',
        data: { user },
      });

      this.setState({
        id: data.id,
        email: data.attributes.email,
        password: '',
        confirm_password: '',
      });
      toast.success('Settings updated!');
    } catch (err) {
      this.setState({
        errors: [...this.state.errors, err.response.data.error],
      });
    }
  };

  render() {
    const { errors, user } = this.state;
    const { email, password, confirm_password } = user;
    return (
      <Row className="d-flex flex-column flex-md-row">
        <Col xs="12" md="12" lg="10" xl="7">
          <Card>
            <CardHeader>Settings</CardHeader>
            <CardBody>
              {errors.length >= 1 &&
                errors.map(err => (
                  <Alert theme="danger" key={err}>
                    {err}
                  </Alert>
                ))}
              <Form>
                <FormGroup>
                  <label htmlFor="#email">Email</label>
                  <FormInput
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="#password">Password</label>
                  <FormInput
                    id="password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="#confirm_password">Confirm Password</label>
                  <FormInput
                    id="confirm_password"
                    type="password"
                    name="confirm_password"
                    value={confirm_password}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Form>
              <Button onClick={this.update}>Update</Button>
              <hr />
              <Profile />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Settings;
