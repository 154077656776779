import React, { useEffect, useState } from 'react';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Legend,
  Rectangle,
} from 'recharts';
import moment from 'moment';
import { Button } from 'shards-react';
import Auth from '../../packs/auth';

const RoundedRectangle = props => {
  let defaultFill = props.fill;
  if (props.shift_time > props.total_time_in_hours) {
    defaultFill = '#F3B7B7';
  }

  if (props.shift_time < props.total_time_in_hours) {
    defaultFill = '#A9E1B9';
  }
  return <rect {...props} rx="7" ry="7" fill={defaultFill} />;
};

const DateTick = props => {
  const { payload, x, y } = props;
  const parts = payload.value.split(' ');
  return (
    <>
      <text x={x} y={y}>
        <tspan
          x={x}
          dy="10"
          fontSize="13"
          fontFamily="Inter"
          fontWeight="600"
          textAnchor="middle"
          fill="#174d7c"
        >
          {parts[0]}
        </tspan>
        <tspan
          x={x}
          y={y + 23}
          fontSize="13"
          fontFamily="Inter"
          textAnchor="middle"
          fontWeight="500"
          fill="#85898c"
        >
          {parts[1]}
        </tspan>
      </text>
    </>
  );
};

const YAxisText = props => {
  const { payload, x, y } = props;
  const { value } = payload;
  if (value === 0 || value === 9) {
    return (
      <text
        x={x}
        y={y}
        fontFamily="IBM Plex Sans"
        fontSize={12}
        fontWeight="bold"
        fill="#c5cbd5"
        dy={value === 0 ? '-5' : 16}
        dx="6"
      >
        {payload.value}HR
      </text>
    );
  }
  return null;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <div className="timesheet_tooltip">
        <p>
          {label}
          <br />
          <span>{payload[0].payload.formatted_time}</span>
        </p>
      </div>
    );
  }

  return null;
};

const TimelogChart = () => {
  const [date, setDate] = useState(moment());
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const query = date ? `?date=${date}` : '';
      const { time_sheets } = await Auth.fetch({
        url: `/time_sheets${query}`,
        method: 'GET',
      });
      setData(time_sheets);
    };

    fetch();
  }, [date]);

  const onPrevWeek = () => {
    const newDate = moment(date)
      .startOf('week')
      .subtract(1, 'day');
    setDate(newDate);
  };

  const onNextWeek = () => {
    const newDate = moment(date)
      .endOf('week')
      .add(1, 'day');
    setDate(newDate);
  };

  return (
    <>
      <h4 className="inter-font font-weight-bold" style={{ fontSize: 18 }}>
        Current time sheets
      </h4>

      <div
        className="card-border dashboard-chart"
        style={{ padding: '33px 21px', overflow: 'hidden', maxWidth: 664 }}
      >
        <BarChart width={678} height={300} data={data} barSize={40}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="formatted_day"
            padding={{ left: 60, right: 40 }}
            scale="point"
            interval={0}
            tickLine={false}
            axisLine={{ stroke: '#e5e5e5' }}
            tick={<DateTick />}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            scale="time"
            interval={0}
            tick={<YAxisText />}
            ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
            padding={{ left: 0, right: 0 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="total_time_in_hours"
            fill="#93D5EB"
            shape={<RoundedRectangle />}
          />
        </BarChart>

        <div className="timesheet_action_wrapper">
          <Button onClick={onPrevWeek}>Prev</Button>
          <div className="legend-wrapper">
            <span className="legend">
              <i className="legend-icon under-time" />
              Under Time
            </span>
            <span className="legend">
              <i className="legend-icon shift" />
              Shift
            </span>
            <span className="legend">
              <i className="legend-icon over-time" />
              Over Time
            </span>
          </div>
          <Button onClick={onNextWeek}>Next</Button>
        </div>
      </div>
    </>
  );
};

export default TimelogChart;
