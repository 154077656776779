import React, { useState, useEffect } from 'react';
import {
	ModalBody,
	FormGroup,
	FormInput,
	Form,
	Button,
	Nav,
	NavItem,
	Fade,
	NavLink,
	FormTextarea
} from 'shards-react';
import moment from 'moment-timezone';
import Auth from '../../packs/auth';
import { updateTimelog } from '.';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DateTimePicker from 'react-datetime-picker';
import Table from '../Table';
import { toast } from 'react-toastify';
import shortHumanizer from '../../packs/shortHumanizer';

const mapDispatchToProps = { updateTimelog };

const TimelogModalBody = ({ timelog, updateTimelog, ...rest }) => {
	const [contentsOpen, setContentsOpen] = useState([true, false, false]);
	const [newTimelog, setNewTimelog] = useState({
		description: '',
		start_at: new Date(),
		end_at: new Date(),
		message: '',
	});
	const [histories, setHistories] = useState([]);
	const [timelogApprovals, setTimelogApprovals] = useState([]);

	useEffect(() => {
		if (timelog) {
			const fetchData = async () => {
				const result = await Auth.fetch({
					url: `/timelog_histories/${timelog.id}`,
					method: 'GET'
				});
				const result2 = await Auth.fetch({
					url: `/timelog_edit_approvals/${timelog.id}`,
					method: 'GET'
				});

				setTimelogApprovals(result2.data);
				setHistories(result.data);
			};

			fetchData();
			setNewTimelog({
				id: timelog.id,
				duration: timelog.attributes.duration,
				description: timelog.attributes.description,
				start_at: new Date(timelog.attributes.start_at),
				end_at: new Date(timelog.attributes.end_at)
			});
		}
	}, []);

	const handleChange = event => {
		const {name, value} = event.target;
		const cloneTimelog = {...newTimelog}
		cloneTimelog[name] = value;
		setNewTimelog(cloneTimelog);
	};

	const handleCalendarChange = name => {
		return date => {
			switch (name) {
				case 'start_at':
					return setNewTimelog({ ...newTimelog, start_at: date });
				case 'end_at':
					return setNewTimelog({ ...newTimelog, end_at: date });
				default:
					return;
			}
		};
	};

	const toggleContents = index => {
		return () => {
			const newContents = [false, false, false];
			newContents[index] = !newContents[index];
			setContentsOpen(newContents);
		};
	};

	if (!newTimelog) {
		return null;
	}

	const update = async () => {
		const { start_at, end_at } = newTimelog;
		const oneSecond = 1000;
		newTimelog.duration = (end_at - start_at) / oneSecond;
		const timelog_id = newTimelog.id;

		delete newTimelog.id;

		try {
			const { data } = await Auth.fetch({
				url: `/timelog_edit_approvals`,
				method: 'POST',
				data: {
					timelog_edit_approval: {
						...newTimelog,
						timelog_id,
					}
				}
			});
			const newTimelogApprovals = [...timelogApprovals]
			newTimelogApprovals.unshift(data)
			toast.success('Request saved.')
			setTimelogApprovals(newTimelogApprovals)
			rest.onClick.call(null)
		} catch(err) {
			toast.error(err.message)
		}
	};

	const convertDuration = duration => {
		return shortHumanizer(duration * 1000);
	};

	const convertToTime = time => {
		return moment(time).format('hh:mm a');
	};

	return (
		<ModalBody
			className='py-2 px-0'
			style={{ maxHeight: '60vh', overflowY: 'auto' }}
		>
			<Nav tabs className='px-2'>
				<NavItem>
					<NavLink
						href='#'
						onClick={toggleContents(0)}
						active={contentsOpen[0]}
					>
						Edit
					</NavLink>
				</NavItem>
        <NavItem>
					<NavLink
						href='#'
						onClick={toggleContents(1)}
						active={contentsOpen[1]}
					>
						Edit Approvals
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						href='#'
						onClick={toggleContents(2)}
						active={contentsOpen[2]}
					>
						Audit Trail
					</NavLink>
				</NavItem>
			</Nav>
			<Fade
				in={contentsOpen[0]}
				style={{ height: !contentsOpen[0] ? 0 : '100%' }}
			>
				<Form className='px-4 py-2'>
					<FormGroup>
						<label htmlFor='#description'>What are you working on?</label>
						<FormInput
							id='description'
							value={newTimelog.description}
							name='description'
							onChange={handleChange}
						/>
					</FormGroup>
					<FormGroup>
						<label htmlFor='#start_in'>Time in</label>
						<DateTimePicker
							value={newTimelog.start_at}
							onChange={handleCalendarChange('start_at')}
							className='form-control'
						/>
					</FormGroup>
					<FormGroup>
						<label htmlFor='#end_at'>Time out</label>
						<DateTimePicker
							value={newTimelog.end_at}
							onChange={handleCalendarChange('end_at')}
							className='form-control'
						/>
					</FormGroup>
					<FormGroup>
						<label htmlFor='#message'>Reason for requesting time change?</label>
						<FormTextarea
							id='message'
							name='message'
							onChange={handleChange}
						>
							{newTimelog.message}
						</FormTextarea>
					</FormGroup>
					<Button onClick={update}>Save Request</Button>
				</Form>
			</Fade>
			<Fade
				in={contentsOpen[1]}
				style={{ height: !contentsOpen[1] ? 0 : '100%' }}
			>
				<Table
					headers={[
						'Working on?',
						'Duration',
						'Time in',
						'Time out',
						'Status',
					]}
				>
					{timelogApprovals.map(t => (
						<tr key={t.id} style={{ cursor: 'pointer' }}>
							<td>{t.attributes.description}</td>
							<td>{convertDuration(t.attributes.duration)}</td>
							<td>{convertToTime(t.attributes.start_at)}</td>
							<td>{convertToTime(t.attributes.end_at)}</td>
              <td>{t.attributes.status}</td>
						</tr>
					))}
				</Table>
			</Fade>
			<Fade
				in={contentsOpen[2]}
				style={{ height: !contentsOpen[2] ? 0 : '100%' }}
			>
				<Table
					headers={[
						'Working on?',
						'Duration',
						'Time in',
						'Time out',
						'Created at'
					]}
				>
					<tr className='bg-primary text-white'>
						<td>{newTimelog.description}</td>
						<td>{convertDuration(newTimelog.duration)}</td>
						<td>{convertToTime(newTimelog.start_at)}</td>
						<td>{convertToTime(newTimelog.end_at)}</td>
            <td />
					</tr>
					{histories.map(t => (
						<tr key={t.id} style={{ cursor: 'pointer' }}>
							<td>{t.attributes.description}</td>
							<td>{convertDuration(t.attributes.duration)}</td>
							<td>{convertToTime(t.attributes.start_at)}</td>
							<td>{convertToTime(t.attributes.end_at)}</td>
							<td>
								{moment
									.utc(t.attributes.created_at)
									.tz('America/New_York')
									.format('MMM DD, YYYY hh:mm:ss a')}
							</td>
						</tr>
					))}
				</Table>
			</Fade>
		</ModalBody>
	);
};

export default withRouter(connect(null, mapDispatchToProps)(TimelogModalBody));
