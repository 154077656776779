import React, {useState, useEffect, useRef} from 'react'
import Interval from '../../packs/interval'
import WorkerTimer from '../../packs/workerTimer.js';
import WebWorker from '../../packs/WebWorker';

const useInterval = (callback, delay) => {
  const cachedCallback = useRef();
  const cachedWorker = useRef();

  useEffect(() => {
    cachedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => {
      cachedCallback.current()
    }

    if (delay !== null) {
      if (typeof(Worker) !== 'undefined') {
        if (!cachedWorker.current) {
          cachedWorker.current = new WebWorker(WorkerTimer);
        }
        // Start the timer by sending the delay
        cachedWorker.current.postMessage(delay);

        // Call the callback on message
        cachedWorker.current.onmessage = (event) => {
          cachedCallback.current(event.data);
        }

        return () => {
          cachedWorker.current.terminate();
          cachedWorker.current = null;
        }
      } else {
        const timer = new Interval(tick, delay);
        timer.run();
        return () => timer.stop();
      }
    }
  }, [delay])
}

export default useInterval
